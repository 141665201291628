import { Box, Button } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
/* import {
	loadContaId,
	loadPermissao,
	postAuthMeAction,
} from '../../actions/actions'; */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import CurrencyFormat from 'react-currency-format';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router';
import vBankLogo from '../../assets/vBankPJAssets/vBankSmallLogoWhite.svg';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LockIcon from '@material-ui/icons/Lock';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupIcon from '@material-ui/icons/Group';
import PixIcon from '@mui/icons-material/Pix';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BlockIcon from '@material-ui/icons/Block';
import useAuth from '../../hooks/useAuth';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PaymentsIcon from '@mui/icons-material/Payments';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CustomButton from '../CustomButton/CustomButton';
import { toast } from 'react-toastify';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { APP_CONFIG } from '../../constants/config';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { loadUserData } from '../../actions/actions';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		backgroundColor: 'white',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
		borderRightWidth: '0px',
		background: APP_CONFIG.mainCollors.drawerSideBar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

function CustomSideBar(props) {
	const dispatch = useDispatch();
	const { id, section } = useParams();
	const token = useAuth();
	/* const contaSelecionada = useSelector((state) => state.conta); */
	/* 	const userData = useSelector((state) => state.userData); */
	const [subMenuTransferencia, setSubMenuTransferencia] = useState(false);
	const [subMenuWallet, setSubMenuWallet] = useState(false);
	const userData = useSelector((state) => state.userData);
	const { window } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const history = useHistory();
	const [isSaldoVisible, setIsSaldoVisible] = useState(true);
	const [permissoes, setPermissoes] = useState([]);

	useEffect(() => {
		dispatch(loadUserData(token));
	}, [token]);

	/* const me = useSelector((state) => state.me); */
	/* const userPermissao = useSelector((state) => state.userPermissao); */
	/* useEffect(() => {
		dispatch(postAuthMeAction(token));
	}, []);
 */
	/* useEffect(() => {
		if (me.id !== undefined) {
			dispatch(loadPermissao(token, me.id));
		}
	}, [me.id]); */

	/* useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]); */

	/* useEffect(() => {
		if (id && token && section !== 'taxa') {
			dispatch(loadContaId(token, id));
		}
	}, [id, token, userData]); */

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const getSideBarItemBackgroundColor = (index) =>
		index === selectedIndex ? 'white' : null;

	const getSideBarItemColor = (index) =>
		index === selectedIndex ? APP_CONFIG.mainCollors.primary : 'white';

	const handleListItemClick = (event, index) => {
		/* if (subMenuTransferencia === true) {
			setSubMenuTransferencia(false);
		}
		if (subMenuWallet === true) {
			setSubMenuWallet(false);
		} */
		setSelectedIndex(index);
	};

	const drawer = (
		<Box
			style={{
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0,
				background: APP_CONFIG.mainCollors.secondaryGradient,
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<Box
				style={{
					width: '100%',
					justifyContent: 'center',
					display: 'flex',
					marginTop: '50px',
				}}
			>
				<img
					src={APP_CONFIG.assets.smallWhiteLogo}
					alt={''}
					style={{
						width:
							APP_CONFIG.titleLogin === 'Aprobank' || 'Simer Bank'
								? '170px'
								: '50px',
						alignSelf: 'center',
					}}
				/>
			</Box>
			<Box className={classes.toolbar} />

			<List style={{ marginLeft: '30px' }}>
				<ListItem
					disabled={props.cadastro ? true : false}
					component={Link}
					button
					selected={selectedIndex === 0}
					onClick={(event) => handleListItemClick(event, 0)}
					to="/dashboard/home"
					style={
						selectedIndex === 0
							? {
									backgroundColor: 'white',
									borderTopLeftRadius: 32,
									borderBottomLeftRadius: 32,
							  }
							: {}
					}
				>
					<ListItemIcon style={{ width: '60px' }}>
						<HomeIcon
							fontSize="50px"
							style={{
								backgroundColor: getSideBarItemBackgroundColor(0),
								color: getSideBarItemColor(0),
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography
							style={
								selectedIndex === 0
									? {
											fontWeight: 'bold',
											fontFamily: 'Montserrat-SemiBold',
											fontSize: '14px',
											color: APP_CONFIG.mainCollors.primary,
									  }
									: {
											fontFamily: 'Montserrat-Regular',
											fontSize: '14px',
											color: 'white',
									  }
							}
						>
							Home
						</Typography>
					</ListItemText>
				</ListItem>
				<ListItem
					disabled={props.cadastro ? true : false}
					button
					selected={selectedIndex === 1}
					onClick={(event) => handleListItemClick(event, 1)}
					component={Link}
					to="/dashboard/conta-digital"
					style={
						selectedIndex === 1
							? {
									backgroundColor: 'white',
									borderTopLeftRadius: 32,
									borderBottomLeftRadius: 32,
							  }
							: {}
					}
				>
					<ListItemIcon>
						<PersonIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(1),
								color: getSideBarItemColor(1),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 1
								? {
										fontWeight: 'bold',
										fontFamily: 'Montserrat-SemiBold',
										fontSize: '14px',
										color: APP_CONFIG.mainCollors.primary,
								  }
								: {
										fontFamily: 'Montserrat-Regular',
										fontSize: '14px',
										color: 'white',
								  }
						}
					>
						Conta Digital
					</Typography>
				</ListItem>
				<ListItem
					disabled={props.cadastro ? true : false}
					style={
						selectedIndex === 2
							? {
									backgroundColor: 'white',
									borderTopLeftRadius: 32,
									borderBottomLeftRadius: 32,
							  }
							: {}
					}
					button
					selected={selectedIndex === 2}
					onClick={(event) => handleListItemClick(event, 2)}
					component={Link}
					to="/dashboard/pix"
				>
					<ListItemIcon>
						<PixIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(2),
								color: getSideBarItemColor(2),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 2
								? {
										fontWeight: 'bold',
										fontFamily: 'Montserrat-SemiBold',
										fontSize: '14px',
										color: APP_CONFIG.mainCollors.primary,
								  }
								: {
										fontFamily: 'Montserrat-Regular',
										fontSize: '14px',
										color: 'white',
								  }
						}
					>
						Pix
					</Typography>
				</ListItem>
				<ListItem
					disabled={props.cadastro ? true : false}
					style={
						selectedIndex === 3
							? {
									backgroundColor: 'white',
									borderTopLeftRadius: 32,
									borderBottomLeftRadius: 32,
							  }
							: {}
					}
					button
					selected={selectedIndex === 3}
					component={Link}
					onClick={() => {
						setSubMenuTransferencia(!subMenuTransferencia);
						setSubMenuWallet(false);
					}}

					//to="/dashboard/lista-de-administradores"
				>
					<ListItemIcon>
						<CompareArrowsIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(3),
								color: getSideBarItemColor(3),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 3
								? {
										fontWeight: 'bold',
										fontFamily: 'Montserrat-SemiBold',
										fontSize: '14px',
										color: APP_CONFIG.mainCollors.primary,
								  }
								: {
										fontFamily: 'Montserrat-Regular',
										fontSize: '14px',
										color: 'white',
								  }
						}
					>
						Transferências
					</Typography>
				</ListItem>
				{subMenuTransferencia && (
					<>
						<ListItem
							disabled={props.cadastro ? true : false}
							style={
								selectedIndex === 3.1
									? {
											backgroundColor: 'white',
											borderTopLeftRadius: 32,
											borderBottomLeftRadius: 32,
											marginBottom: 10,
											marginTop: 10,
									  }
									: { marginBottom: 10, marginTop: 10 }
							}
							button
							selected={selectedIndex === 3.1}
							onClick={(event) => {
								handleListItemClick(event, 3.1);
							}}
							to="/dashboard/extratoTED"
							component={Link}
						>
							<Typography
								style={{
									fontWeight: 'bold',
									fontFamily: 'Montserrat-SemiBold',
									fontSize: '14px',
									color:
										selectedIndex === 3.1
											? APP_CONFIG.mainCollors.primary
											: 'white',
								}}
							>
								TED
							</Typography>
						</ListItem>
						<ListItem
							disabled={props.cadastro ? true : false}
							style={
								selectedIndex === 3.2
									? {
											backgroundColor: 'white',
											borderTopLeftRadius: 32,
											borderBottomLeftRadius: 32,
											marginBottom: 10,
											marginTop: 10,
									  }
									: { marginBottom: 10, marginTop: 10 }
							}
							button
							selected={selectedIndex === 3.2}
							onClick={(event) => {
								handleListItemClick(event, 3.2);
							}}
							component={Link}
							to="/dashboard/extratoP2P"
						>
							<Typography
								style={{
									fontWeight: 'bold',
									fontFamily: 'Montserrat-SemiBold',
									fontSize: '14px',
									color:
										selectedIndex === 3.2
											? APP_CONFIG.mainCollors.primary
											: 'white',
								}}
							>
								P2P
							</Typography>
						</ListItem>
					</>
				)}
				<ListItem
					disabled={props.cadastro ? true : false}
					style={
						selectedIndex === 4
							? {
									backgroundColor: 'white',
									borderTopLeftRadius: 32,
									borderBottomLeftRadius: 32,
							  }
							: {}
					}
					button
					selected={selectedIndex === 4}
					onClick={(event) => handleListItemClick(event, 4)}
					component={Link}
					to="/dashboard/lista-pagamentos"
				>
					<ListItemIcon>
						<PaymentsIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '45px',
								backgroundColor: getSideBarItemBackgroundColor(4),
								color: getSideBarItemColor(4),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 4
								? {
										fontWeight: 'bold',
										fontFamily: 'Montserrat-SemiBold',
										fontSize: '14px',
										color: APP_CONFIG.mainCollors.primary,
								  }
								: {
										fontFamily: 'Montserrat-Regular',
										fontSize: '14px',
										color: 'white',
								  }
						}
					>
						Pagamentos
					</Typography>
				</ListItem>
				<ListItem
					disabled={props.cadastro ? true : false}
					style={
						selectedIndex === 5
							? {
									backgroundColor: 'white',
									borderTopLeftRadius: 32,
									borderBottomLeftRadius: 32,
							  }
							: {}
					}
					button
					selected={selectedIndex === 5}
					onClick={(event) => handleListItemClick(event, 5)}
					component={Link}
					to="/dashboard/folha-de-pagamento"
				>
					<ListItemIcon>
						<AssignmentIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(5),
								color: getSideBarItemColor(5),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 5
								? {
										fontWeight: 'bold',
										fontFamily: 'Montserrat-SemiBold',
										fontSize: '14px',
										color: APP_CONFIG.mainCollors.primary,
								  }
								: {
										fontFamily: 'Montserrat-Regular',
										fontSize: '14px',
										color: 'white',
								  }
						}
					>
						Folha de pagamento
					</Typography>
				</ListItem>
				{APP_CONFIG.AbaCartoes && (
					<ListItem
						disabled={props.cadastro ? true : false}
						style={
							selectedIndex === 6
								? {
										backgroundColor: 'white',
										borderTopLeftRadius: 32,
										borderBottomLeftRadius: 32,
								  }
								: {}
						}
						button
						selected={selectedIndex === 6}
						onClick={(event) => handleListItemClick(event, 6)}
						component={Link}
						to="/dashboard/cartoes"
					>
						<ListItemIcon>
							<CreditCardIcon
								style={{
									width: '48px',
									marginRight: '10px',
									fontSize: '48px',
									backgroundColor: getSideBarItemBackgroundColor(6),
									color: getSideBarItemColor(6),
									borderRadius: '33px',
									padding: '5px',
								}}
							/>
						</ListItemIcon>
						<Typography
							style={
								selectedIndex === 6
									? {
											fontWeight: 'bold',
											fontFamily: 'Montserrat-SemiBold',
											fontSize: '14px',
											color: APP_CONFIG.mainCollors.primary,
									  }
									: {
											fontFamily: 'Montserrat-Regular',
											fontSize: '14px',
											color: 'white',
									  }
							}
						>
							Cartões
						</Typography>
					</ListItem>
				)}
				<ListItem
					disabled={props.cadastro ? true : false}
					style={
						selectedIndex === 7
							? {
									backgroundColor: 'white',
									borderTopLeftRadius: 32,
									borderBottomLeftRadius: 32,
							  }
							: {}
					}
					button
					selected={selectedIndex === 7}
					//onClick={() => { toast.warning('Em desenvolvimento.') }}
					onClick={(event) => handleListItemClick(event, 7)}
					component={Link}
					to="/dashboard/lista-boletos"
				>
					<ListItemIcon>
						<ReceiptIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(7),
								color: getSideBarItemColor(7),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 7
								? {
										fontWeight: 'bold',
										fontFamily: 'Montserrat-SemiBold',
										fontSize: '14px',
										color: APP_CONFIG.mainCollors.primary,
								  }
								: {
										fontFamily: 'Montserrat-Regular',
										fontSize: '14px',
										color: 'white',
								  }
						}
					>
						Boleto
					</Typography>
				</ListItem>
				<ListItem
					disabled={props.cadastro ? true : false}
					style={
						selectedIndex === 8
							? {
									backgroundColor: 'white',
									borderTopLeftRadius: 32,
									borderBottomLeftRadius: 32,
							  }
							: {}
					}
					button
					selected={selectedIndex === 8}
					// onClick={(event) => handleListItemClick(event, 8)}
					onClick={() => {
						setSubMenuWallet(!subMenuWallet);
						setSubMenuTransferencia(false);
					}}
					component={Link}
					// to="/dashboard/walletVBank"
				>
					<ListItemIcon>
						<PaymentsIcon
							style={{
								width: '48px',
								marginRight: '10px',
								fontSize: '48px',
								backgroundColor: getSideBarItemBackgroundColor(8),
								color: getSideBarItemColor(8),
								borderRadius: '33px',
								padding: '5px',
							}}
						/>
					</ListItemIcon>
					<Typography
						style={
							selectedIndex === 8
								? {
										fontWeight: 'bold',
										fontFamily: 'Montserrat-SemiBold',
										fontSize: '14px',
										color: APP_CONFIG.mainCollors.primary,
								  }
								: {
										fontFamily: 'Montserrat-Regular',
										fontSize: '14px',
										color: 'white',
								  }
						}
					>
						Wallet
					</Typography>
				</ListItem>
				{subMenuWallet && (
					<>
						<ListItem
							disabled={props.cadastro ? true : false}
							style={
								selectedIndex === 8.1
									? {
											backgroundColor: 'white',
											borderTopLeftRadius: 32,
											borderBottomLeftRadius: 32,
											marginBottom: 10,
											marginTop: 10,
									  }
									: { marginBottom: 10, marginTop: 10 }
							}
							button
							selected={selectedIndex === 8.1}
							onClick={(event) => {
								handleListItemClick(event, 8.1);
							}}
							to="/dashboard/walletVBank"
							component={Link}
						>
							<Typography
								style={{
									fontWeight: 'bold',
									fontFamily: 'Montserrat-SemiBold',
									fontSize: '14px',
									color:
										selectedIndex === 8.1
											? APP_CONFIG.mainCollors.primary
											: 'white',
								}}
							>
								{APP_CONFIG.sidebarRede}
							</Typography>
						</ListItem>
						<ListItem
							disabled={props.cadastro ? true : false}
							style={
								selectedIndex === 8.2
									? {
											backgroundColor: 'white',
											borderTopLeftRadius: 32,
											borderBottomLeftRadius: 32,
											marginBottom: 10,
											marginTop: 10,
									  }
									: { marginBottom: 10, marginTop: 10 }
							}
							button
							selected={selectedIndex === 8.2}
							onClick={(event) => {
								handleListItemClick(event, 8.2);
							}}
							to="/dashboard/walletCompartilhado"
							component={Link}
						>
							<Typography
								style={{
									fontWeight: 'bold',
									fontFamily: 'Montserrat-SemiBold',
									fontSize: '14px',
									color:
										selectedIndex === 8.2
											? APP_CONFIG.mainCollors.primary
											: 'white',
								}}
							>
								Cobranças compartilhadas
							</Typography>
						</ListItem>
					</>
				)}
				{userData.status_adquirencia === 'approved' && (
					<ListItem
						disabled={props.cadastro ? true : false}
						style={
							selectedIndex === 9
								? {
										backgroundColor: 'white',
										borderTopLeftRadius: 32,
										borderBottomLeftRadius: 32,
								  }
								: {}
						}
						button
						selected={selectedIndex === 9}
						onClick={(event) => {
							handleListItemClick(event, 9);
						}}
						to="/dashboard/adquirencia"
						component={Link}
					>
						<ListItemIcon>
							<CreditScoreIcon
								style={{
									width: '48px',
									marginRight: '10px',
									fontSize: '48px',
									backgroundColor: getSideBarItemBackgroundColor(9),
									color: getSideBarItemColor(9),
									borderRadius: '33px',
									padding: '5px',
								}}
							/>
						</ListItemIcon>
						<Typography
							style={
								selectedIndex === 9
									? {
											fontWeight: 'bold',
											fontFamily: 'Montserrat-SemiBold',
											fontSize: '14px',
											color: APP_CONFIG.mainCollors.primary,
									  }
									: {
											fontFamily: 'Montserrat-Regular',
											fontSize: '14px',
											color: 'white',
									  }
							}
						>
							Adquirência
						</Typography>
					</ListItem>
				)}
			</List>
			{userData && userData.saldo && userData.saldo.valor && (
				<Box
					style={{
						display: 'flex',
						alignSelf: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Montserrat-Regular',
							fontSize: '20px',
							color: 'white',
							marginTop: '30px',
						}}
					>
						Saldo disponível:
					</Typography>
					{isSaldoVisible ? (
						<>
							<Typography
								style={{
									fontFamily: 'Montserrat-Regular',
									fontSize: '25px',
									color: 'white',
									marginTop: '10px',
								}}
							>
								R${' '}
								{parseFloat(userData.saldo.valor).toLocaleString(
									'pt-br',
									{
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}
								)}
							</Typography>
						</>
					) : (
						<Typography
							style={{
								fontFamily: 'Montserrat-Regular',
								fontSize: '25px',
								color: 'white',
								marginTop: '10px',
							}}
						>
							*******
						</Typography>
					)}

					<Button onClick={() => setIsSaldoVisible(!isSaldoVisible)}>
						{isSaldoVisible ? (
							<VisibilityOffIcon style={{ color: 'white' }} />
						) : (
							<VisibilityIcon style={{ color: 'white' }} />
						)}
					</Button>
				</Box>
			)}

			{/* {id &&
			token &&
			section !== 'taxa' &&
			section !== 'detalhes-pre-conta' ? (
				<Box
					style={{ color: 'black' }}
					display="flex"
					flexDirection="column"
					alignContent="center"
					alignItems="center"
					marginBottom="30px"
				>
					<Typography variant="h5" style={{ color: 'white' }}>
						Conta Selecionada:{' '}
					</Typography>
					<Typography
						style={{ wordWrap: 'break-word', color: 'white' }}
						align="center"
					>
						{contaSelecionada.nome ? contaSelecionada.nome : null}
					</Typography>
					<Typography
						style={{ wordWrap: 'break-word', color: 'white' }}
						align="center"
					>
						{contaSelecionada.razao_social
							? contaSelecionada.razao_social
							: null}
					</Typography>
					<Typography style={{ color: 'white' }}>
						{contaSelecionada.documento
							? contaSelecionada.documento
							: null}
					</Typography>
					<Typography style={{ color: 'white' }}>
						{contaSelecionada.cnpj ? contaSelecionada.cnpj : null}
					</Typography>
					<Typography style={{ color: 'white' }}>
						{contaSelecionada.saldo ? (
							<CurrencyFormat
								value={contaSelecionada.saldo.valor.replace('.', ',')}
								displayType={'text'}
								thousandSeparator={'.'}
								decimalSeparator={','}
								prefix={'R$ '}
								renderText={(value) => <div> Saldo: {value}</div>}
							/>
						) : null}
					</Typography>
				</Box>
			) : null} */}

			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					justifyContent: 'flex-end',
					alignItems: 'center',
				}}
			>
				<Box
					style={{
						marginBottom: '0px',
						justifyContent: 'center',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					{/* <CustomButton
						color="purple"
						style={{ width: '0.9rem' }}
						variant="contained"
						onClick={() => {
							localStorage.removeItem('@auth');
							history.push('/login');
						}}
					>
						Sair
					</CustomButton> */}
				</Box>
			</Box>
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<div className={classes.root}>
			<CssBaseline />

			<nav className={classes.drawer} aria-label="mailbox folders">
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation="css">
					<Drawer
						container={container}
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
		</div>
	);
}

CustomSideBar.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default CustomSideBar;
