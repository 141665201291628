import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { loadPermissao, postAuthMeAction } from '../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import AccountStatement from '../pages/AccountStatement/AccountStatement';
import AccountSubscriptions from '../pages/AccountSubscriptions/AccountSubscriptions';
import ChavesPix from '../pages/ChavesPix/ChavesPix';
import CreditCardBillingList from '../pages/CreditCardBillingList/CreditCardBillingList';
import CustomSideBar from '../components/CustomSideBar/CustomSideBar';
import Dashboard from '../pages/Dashboard/Dashboard';
import DetalhesPreConta from '../pages/DetalhesPreConta/DetalhesPreConta';
import EditFees from '../pages/EditFees/EditFees';
import EditarDadosDaConta from '../pages/EditarDadosDaConta/EditarDadosDaConta';
import FutureTransactions from '../pages/FutureTransactions/FutureTransactions';
import GerenciarListaDeContas from '../pages/GerenciarListaDeContas/GerenciarListaDeContas';

import GiftCardDetails from '../pages/GiftCardDetails/GiftCardDetails';
import GiftCardsList from '../pages/GiftCardsList/GiftCardsList';
import JeittoAdm from '../pages/JeittoAdm/index';
import ListPartner from '../pages/ListPartners/ListPartners';
import ListaDeAdministradores from '../pages/ListaDeAdministradores/ListaDeAdministradores';
import ListaDeContas from '../pages/ListaDeContas/ListaDeContas';
import ListaDispositivosBloqueados from '../pages/ListaDispositivosBloqueados/ListaDispositivosBloqueados';
import Logs from '../pages/Logs/Logs';
import ManageFees from '../pages/ManageFees/ManageFees';
import NewAccountFees from '../pages/NewAccountFees/NewAccountFees';
import Payers from '../pages/Payers/Payers';
import PaymentBooklet from '../pages/PaymentBooklet/PaymentBooklet';
import PaymentLink from '../pages/PaymentLink/PaymentLink';
import PaymentSlipList from '../pages/PaymentSlipList/PaymentSlipList';
import PixDetails from '../pages/PixDetails/PixDetails';
import PixTransactions from '../pages/PixTransactions/PixTransactions';
import RechargeCellphoneDetails from '../pages/RechargeCellphoneDetails/RechargeCellphoneDetails';
import RechargeCellphoneList from '../pages/RechargeCellphoneList/RechargeCellphoneList';
import RechargeCellphoneListAdmin from '../pages/RechargeCellphoneListAdmin/RechargeCellphoneListAdmin';
import GiftCardsListAdmin from '../pages/GiftCardsListAdmin/GiftCardsListAdmin';
import TedDetails from '../pages/TedDetails/TedDetails';
import TedTransactions from '../pages/TedTransactions/TedTransactions';
import TransactionDetails from '../pages/TransactionDetails/TransactionDetails';
import TransactionHistory from '../pages/TransactionHistory/TransactionHistory';
import TransferDetails from '../pages/TransferDetails/TransferDetails';
import TransferHistory from '../pages/TransferHistory/TransferHistory';
import UserPermissions from '../pages/UserPermissions/UserPermissions';
import { toast } from 'react-toastify';
import useAuth from '../hooks/useAuth';
import { useParams } from 'react-router';
import Blacklist from '../pages/Blacklist/Blacklist';
import ExtratoPix from '../pages/ExtratoPix/ExtratoPix';
import ExtratoP2P from '../pages/ExtratoP2P/ExtratoP2P';
import ExtratoTED from '../pages/ExtratoTED/ExtratoTED';
import PaginaPagamentos from '../pages/PaginaPagamentos/PaginaPagamentos';
import PaginaBoletos from '../pages/PaginaBoletos/PaginaBoletos';
import ContaDigital from '../pages/ContaDigital/ContaDigital';
import WalletVBank from '../pages/WalletVBank/WalletVBank';
import WalletCompartilhada from '../pages/WalletCompartilhada/WalletCompartilhada';
import FolhaDePagamento from '../pages/FolhaDePagamento/FolhaDePagamento';
import CadastrarFuncionariosGrupos from '../pages/CadastrarFuncionariosGrupos/CadastrarFuncionariosGrupos';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import ListaFuncionariosGrupos from '../pages/ListaFuncionariosGrupos/ListaFuncionariosGrupos';
import ListaFolhaDePagamentoAutorizar from '../pages/ListaFolhaDePagamentoAutorizar/ListaFolhaDePagamentoAutorizar';
import ListaFolhaDePagamento from '../pages/ListaFolhaDePagamento/ListaFolhaDePagamento';
import CadastrarFolhaDePagamento from '../pages/CadastrarFolhaDePagamento/CadastrarFolhaDePagamento';
import ListaConsultaPagamento from '../pages/ListaConsultaPagamento/ListaConsultaPagamento';
import ListaArquivosLote from '../pages/ListaArquivosLote/ListaArquivosLote';
import Cartoes from '../pages/Cartoes/Cartoes';
import PrintFolhaDePagamento from '../pages/PrintFolhaDePagamento/PrintFolhaDePagamento';
import { APP_CONFIG } from '../constants/config';
import Adquirencia from '../pages/Adquirencia/Adquirencia';
import ListaMaquinaVirtualCartao from '../pages/ListaMaquinaVituralCartao/ListaMaquinaVirtualCartao';
import ListaCobrar from '../pages/ListaCobrar/ListaCobrar';
import { getLancamentosFuturos } from '../services/services';
import ListaLancamentosFuturos from '../pages/ListaLancamentosFuturos/ListaLancamentosFuturos';
import ListaPagadores from '../pages/ListaPagadores/ListaPagadores';
import ListaHistoricoDeTransacoes from '../pages/ListaHistoricoDeTransacoes/ListaHistoricoDeTransacoes';
import ListaLinkPagamento from '../pages/ListaLinkPagamento/ListaLinkPagamento';
import NovoLinkPagamento from '../pages/NovoLinkPagamento/NovoLinkPagamento';
import DetalhesLinkPagamento from '../pages/DetalhesLinkPagamento/DetalhesLinkPagamento';
import CobrarUsuario from '../pages/CobrarUsuario/CobrarUsuario';
import SubscriptionPlans from '../pages/SubscriptionPlans/SubscriptionPlans';
import NewSubscriptionPlans from '../pages/NewSubscriptionPlans/NewSubscriptionPlans';
import NewAccountSubscriptions from '../pages/NewAccountSubscriptions/NewAccountSubscriptions';
import ListaTarifas from '../pages/ListaTarifas/ListaTarifas';
import ListaExportacoesSolicitadas from '../pages/ListaExportacoesSolicitadas/ListaExportacoesSolicitadas';
import ListaTerminaisPOS from '../pages/ListaTerminaisPOS/ListaTerminaisPOS';
import DetalhesTerminalPOS from '../pages/DetalhesTerminalPOS/DetalhesTerminalPOS';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: '100vh',
		width: '100%',
		margin: '0px',
		padding: '0px',
	},
	sideBarContainer: {
		display: 'flex',
		FlexDirection: 'column',
		width: '25%',
		height: '100vh',
		margin: '0px',
	},
	contentAreaContainer: {
		backgroundColor: '#fff',
		width: '100%',
		/* padding: '50px 50px 10px 50px', */
	},
	pageHeaderContainer: {
		backgroundColor: 'white',
		width: '100%',
		height: '35%',
	},
}));

const SwitchContents = () => {
	const classes = useStyles();
	const { section, id, subsection, subsectionId } = useParams();
	const token = useAuth();
	const dispatch = useDispatch();
	const me = useSelector((state) => state.me);
	const userPermissao = useSelector((state) => state.userPermissao);

	const gerenciarPermissao = useSelector((state) => state.gerenciarPermissao);
	const [permissoes, setPermissoes] = useState([]);

	if (token) {
		window.$crisp = [];
		window.CRISP_WEBSITE_ID = APP_CONFIG.crispId;
		(function () {
			var d = document;
			var s = d.createElement('script');
			s.src = 'https://client.crisp.chat/l.js';
			s.async = 1;
			d.getElementsByTagName('head')[0].appendChild(s);
		})();
	}
	useEffect(() => {
		dispatch(postAuthMeAction(token));
	}, []);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	useEffect(() => {
		if (me.id !== undefined) {
			dispatch(loadPermissao(token, me.id));
		}
	}, [me.id]);

	let content = null;

	switch (section) {
		case 'home':
			content = <Dashboard />;
			break;

		case 'conta-digital':
			content = <ContaDigital />;
			break;

		case 'extrato':
			content = <AccountStatement />;

			break;

		case 'folha-de-pagamento':
			content = <FolhaDePagamento />;
			switch (subsection) {
				case 'autorizar-pagamentos-de-salarios':
					content = <ListaFolhaDePagamentoAutorizar />;
					break;

				case 'lista-folhas-de-pagamento':
					content = <ListaFolhaDePagamento />;
					break;

				case 'cadastrar-funcionarios-e-grupos':
					content = <CadastrarFuncionariosGrupos />;
					break;

				case 'cadastrar-folha-de-pagamento':
					content = <CadastrarFolhaDePagamento />;
					break;

				case 'lista-funcionarios-e-grupos':
					content = <ListaFuncionariosGrupos />;
					break;

				case 'consultar-pagamentos':
					content = <ListaConsultaPagamento />;
					break;

				case 'arquivos-lote':
					content = <ListaArquivosLote />;
					break;

				case 'print':
					content = <PrintFolhaDePagamento />;
					break;
				default:
			}

			break;

		case 'adquirencia':
			content = <Adquirencia />;
			switch (subsection) {
				case 'maquina-virtual-cartao':
					content = <ListaMaquinaVirtualCartao />;
					if (subsectionId) {
						content = <TransactionDetails />;
					}
					/* switch (subsectionId) {
						case !null:
							content = null;
							break;
					} */
					break;

				case 'cobrar':
					content = <ListaCobrar />;
					if (subsectionId) {
						content = <CobrarUsuario />;
					}
					break;

				case 'lancamentos-futuros':
					content = <ListaLancamentosFuturos />;

					break;

				case 'pagadores':
					content = <ListaPagadores />;

					break;

				case 'historico-de-transacoes':
					content = <ListaHistoricoDeTransacoes />;

					break;

				case 'link-de-pagamento':
					content = <ListaLinkPagamento />;
					if (subsectionId) {
						content = <DetalhesLinkPagamento />;
					}
					break;

				case 'novo-link-pagamento':
					content = <NovoLinkPagamento />;

					break;

				case 'cobranca-recorrente':
					content = <AccountSubscriptions />;

					break;

				case 'nova-assinatura':
					content = <NewAccountSubscriptions />;

					break;

				case 'planos-de-assinaturas':
					content = <SubscriptionPlans />;
					if (subsectionId) {
						content = <NewSubscriptionPlans />;
					}
					break;

				case 'criar-plano-de-assinatura':
					content = <NewSubscriptionPlans />;

					break;

				case 'tarifas':
					content = <ListaTarifas />;

					break;

				case 'exportacoes-solicitadas':
					content = <ListaExportacoesSolicitadas />;

					break;

				case 'terminais-pos':
					content = <ListaTerminaisPOS />;
					if (subsectionId) {
						content = <DetalhesTerminalPOS />;
					}
					break;
				/* case 'lista-folhas-de-pagamento':
					content = <ListaFolhaDePagamento />;
					break;

				case 'cadastrar-funcionarios-e-grupos':
					content = <CadastrarFuncionariosGrupos />;
					break;

				case 'cadastrar-folha-de-pagamento':
					content = <CadastrarFolhaDePagamento />;
					break;

				case 'lista-funcionarios-e-grupos':
					content = <ListaFuncionariosGrupos />;
					break;

				case 'consultar-pagamentos':
					content = <ListaConsultaPagamento />;
					break;

				case 'arquivos-lote':
					content = <ListaArquivosLote />;
					break;

				case 'print':
					content = <PrintFolhaDePagamento />;
					break; */
				default:
			}

			break;

		/* case 'taxa':
				switch (subsection) {
					case 'editar': {
						switch (id) {
							default:
								content = <EditFees />;
								break;
						}
						break;
					}
					default:
						content = null;
				}
				break; */

		case 'editar-conta':
			switch (id) {
				default:
					content =
						permissoes.includes(
							'Atendimento - Consulta de dados cadastrais(E-mail, Telefone, Endereço e CPF)'
						) || permissoes.includes('Administrador - Acesso total') ? (
							<EditarDadosDaConta />
						) : null;
			}

			break;

		case 'gerenciar-contas':
			switch (id) {
				default:
					content =
						permissoes.includes(
							'Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação'
						) || permissoes.includes('Administrador - Acesso total') ? (
							<GerenciarListaDeContas />
						) : null;
					switch (subsection) {
						case 'cobrancas-credito':
							content = <CreditCardBillingList />;
							break;

						case 'boleto':
							content = <PaymentSlipList />;
							break;

						case 'carne':
							content = <PaymentBooklet />;
							break;

						case 'link-pagamento':
							content = <PaymentLink />;
							break;

						/* case 'lancamentos-futuros':
							content = <FutureTransactions />;
							break; */

						/* case 'extrato':
							content =
								permissoes.includes(
									'Atendimento - Consulta de extrato'
								) ||
								permissoes.includes('Administrador - Acesso total') ? (
									<AccountStatement />
								) : null;
							break; */

						case 'assinaturas':
							content = <AccountSubscriptions />;
							break;

						case 'historico-de-transacoes':
							content =
								permissoes.includes(
									'Operações - Transações e histórico de transações não concluídas'
								) ||
								permissoes.includes('Administrador - Acesso total') ? (
									<TransactionHistory />
								) : null;

							break;

						case 'historico-transferencia':
							content = <TransferHistory />;

							break;

						case 'detalhes-transacao':
							content = <TransactionDetails />;

							break;

						case 'pagadores':
							content = <Payers />;
							break;

						case 'transferencia-ted':
							content = <TedTransactions />;
							break;

						case 'transacoes-pix':
							content = <PixTransactions />;
							break;

						case 'chaves-pix':
							content = <ChavesPix />;
							break;

						case 'gift-cards':
							content = <GiftCardsList />;
							break;

						case 'detalhes-gift-card':
							content = <GiftCardDetails />;
							break;

						case 'recarga-celular':
							content = <RechargeCellphoneList />;
							break;

						case 'detalhes-recarga':
							content = <RechargeCellphoneDetails />;
							break;

						case 'detalhes-transferencia':
							content = <TransferDetails />;
							break;

						case 'detalhes-ted':
							content = <TedDetails />;
							break;

						case 'detalhes-pix':
							content = <PixDetails />;
							break;

						default:
					}
			}
			break;

		case 'lista-de-administradores':
			switch (id) {
				default:
					content =
						permissoes.includes(
							'Operações - Gerencimento de administradores'
						) || permissoes.includes('Administrador - Acesso total') ? (
							<ListaDeAdministradores />
						) : null;
					switch (subsection) {
						case 'permissoes':
							content =
								permissoes.includes(
									'Operações - Gerencimento de administradores'
								) ||
								permissoes.includes('Administrador - Acesso total') ? (
									<UserPermissions />
								) : null;
							break;

						default:
					}
			}
			break;

		case 'taxas':
			content = <ManageFees />;
			break;

		case 'partners':
			content = <ListPartner />;
			break;

		case 'jeitto':
			content =
				permissoes.includes('Parceiros - Visualizar Jeitto') ||
				permissoes.includes('Administrador - Acesso total') ? (
					<JeittoAdm />
				) : null;
			break;

		case 'recarga-celular-admin':
			content =
				permissoes.includes('Parceiros - Visualizar Recargar') ||
				permissoes.includes('Administrador - Acesso total') ? (
					<RechargeCellphoneListAdmin />
				) : null;
			break;

		case 'gift-cards-admin':
			content =
				permissoes.includes('Parceiros - Visualizar GiftCard') ||
				permissoes.includes('Administrador - Acesso total') ? (
					<GiftCardsListAdmin />
				) : null;
			break;

		case 'logs':
			content =
				permissoes.includes('Operações - Visualizar Logs') ||
				permissoes.includes('Administrador - Acesso total') ? (
					<Logs />
				) : null;
			break;

		case 'taxa':
			switch (subsection) {
				case 'editar': {
					switch (id) {
						default:
							content = <EditFees />;
							break;
					}
					break;
				}
				default:
					content = null;
			}
			break;

		case 'nova-taxa':
			content = <NewAccountFees />;
			break;

		/* case 'lista-pre-contas':
			content = <GerenciarListaPreContas />;
			break; */

		case 'extratoTED':
			content = <ExtratoTED />;
			break;

		case 'walletVBank':
			content = <WalletVBank />;
			break;

		case 'walletCompartilhado':
			content = <WalletCompartilhada />;
			break;

		case 'extratoP2P':
			content = <ExtratoP2P />;
			break;

		case 'cartoes':
			content = APP_CONFIG.AbaCartoes && <Cartoes />;
			break;

		case 'pix':
			content = <ExtratoPix />;
			break;

		case 'lista-pagamentos':
			content = <PaginaPagamentos />;
			break;

		case 'lista-boletos':
			content = <PaginaBoletos />;
			break;

		case 'lista-dispositivos-bloqueados':
			content = <ListaDispositivosBloqueados />;
			break;

		case 'blacklist':
			content = <Blacklist />;
			break;

		case 'detalhes-pre-conta':
			switch (id) {
				default:
					content = <DetalhesPreConta />;
					break;
			}
			break;

		default:
			content = null;
			break;
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.sidebarContainer}>
				{subsection && subsection === 'print' ? null : (
					<CustomSideBar cadastro={false} />
				)}

				<Box className={classes.pageHeaderContainer}></Box>
			</Box>

			<Box className={classes.contentAreaContainer}>{content}</Box>
		</Box>
	);
};

export default SwitchContents;
