import {
	Box,
	Step,
	StepLabel,
	Stepper,
	Typography,
	useTheme,
	Grid,
	TextField,
	StepContent,
	StepConnector,
	Button,
	Divider,
	CardMedia,
	DialogActions,
	IconButton,
	CardActionArea,
	Card,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	MenuItem,
	Select,
} from '@material-ui/core';
import { Link, useHistory, generatePath } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import CustomSideBar from '../../components/CustomSideBar/CustomSideBar';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SearchIcon from '@mui/icons-material/Search';
import ReactCodeInput from 'react-code-input';
import tokenImage from '../../assets/vBankPJAssets/tokenImage.svg';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomHeader from '../../components/CustomHeader/CustomHeader';
import CustomFowardButton from '../../components/CustomFowardButton/CustomFowardButton';
import { getCep } from '../../services/services';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import ReactInputMask from 'react-input-mask';
import CustomLineChart from '../../components/CustomLineChart/CustomLineChart';
import CustomCard from '../../components/CustomCard/CustomCard';
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import {
	loadExtratoFilter,
	loadUserData,
	getListaBannerAction,
	setRedirecionarTransferencia,
	setRedirecionarValorTransferencia,
} from '../../actions/actions';
import useAuth from '../../hooks/useAuth';
import CustomTable from '../../components/CustomTable/CustomTable';
import PersonIcon from '@material-ui/icons/Person';
import CustomRoundedCard from '../../components/CustomRoundedCard/CustomRoundedCard';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Carousel } from 'react-responsive-carousel';
import ImageGallery from 'react-image-gallery';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CurrencyInput from 'react-currency-input';
import { APP_CONFIG } from '../../constants/config';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',

		/* flexGrow: 1, */
		/* width: '100vw',
		height: '100vh', */
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '10px',
	},
	header: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'space-around',
		alignItems: 'center',
		width: '100%',
	},
	dadosBox: {
		display: 'flex',
		flexDirection: 'row',
		/* alignItems: 'center', */
		/* justifyContent: 'center', */
		marginTop: '50px',
		marginLeft: '30px',
	},
	cardContainer: {
		display: 'flex',
		width: '100%',
		height: '100%',
		justifyContent: 'space-between',
	},
	contadorStyle: {
		display: 'flex',
		fontSize: '30px',
		fontFamily: 'Montserrat-SemiBold',
	},
	currencyInput: {
		marginBottom: '6px',

		alignSelf: 'center',
		textAlign: 'center',
		height: 45,
		fontSize: 17,
		borderWidth: '1px !important',
		borderRadius: 27,
		border: 'none',
		color: APP_CONFIG.mainCollors.primary,
		backgroundColor: 'transparent',
		fontFamily: 'Montserrat-Regular',
	},
}));
export default function Dashboard() {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();
	const userData = useSelector((state) => state.userData);
	const extrato = useSelector((state) => state.extrato);
	const listaBanner = useSelector((state) => state.listaBanner);
	const token = useAuth();
	const [loading, setLoading] = useState(false);
	const [banner, setBanner] = useState([]);
	const [modalRetirada, setModalRetirada] = useState(false);
	const [textRetirada, setTextRetirada] = useState(false);
	const [valorRetirada, setValorRetirada] = useState(null);
	const [valorTransferencia, setValorTransferencia] = useState(null);
	const [tipoTransferencia, setTipoTransferencia] = useState('');

	var firstBanner = banner[0];

	const handleContinuar = () => {
		if (tipoTransferencia !== null && valorRetirada !== null) {
			dispatch(setRedirecionarTransferencia(true));
			dispatch(setRedirecionarValorTransferencia(valorRetirada));
			if (tipoTransferencia === 'Pix') {
				const path = generatePath('/dashboard/pix');
				history.push(path);
			}
			if (tipoTransferencia === 'TED') {
				const path = generatePath('/dashboard/extratoTED');
				history.push(path);
			}
			if (tipoTransferencia === 'P2P') {
				const path = generatePath('/dashboard/extratoP2P');
				history.push(path);
			}
		} else {
			toast.error('Preencha todos os campos');
		}
	};

	useEffect(() => {
		dispatch(loadUserData(token));
	}, [token]);

	useEffect(() => {
		dispatch(loadExtratoFilter(token, '', '', '', '', '', '', ''));
	}, [token]);

	useEffect(() => {
		dispatch(getListaBannerAction(token, '', ''));
	}, [token]);

	useEffect(() => {
		if (listaBanner && listaBanner.data) {
			setBanner(
				listaBanner.data.map((item, index) => {
					return item;
				})
			);
		}
	}, [listaBanner]);

	moment.locale();

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />

			<Box className={classes.main}>
				<CustomHeader isSearchVisible={true} />

				<Box className={classes.dadosBox}>
					<Box
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Grid container spacing={2} style={{ marginTop: '0px' }}>
							<Grid item sm={3} xs={12}>
								<Box
									style={{
										display: 'flex',
										backgroundColor:
											APP_CONFIG.mainCollors.backgrounds,
										/* height: '100px', */
										borderRadius: '17px',
										flexDirection: 'column',

										alignItems: 'center',
									}}
								>
									<Typography
										style={{
											fontFamily: 'Montserrat-Regular',
											fontSize: '16px',
											color: APP_CONFIG.mainCollors.primary,
											marginTop: '30px',
										}}
									>
										Saldo disponível
									</Typography>
									<Box
										style={{
											width: '80%',
											height: '1px',
											backgroundColor:
												APP_CONFIG.mainCollors.primary,
										}}
									/>
									{userData &&
										userData.saldo &&
										userData.saldo.valor && (
											<Typography
												style={{
													fontFamily: 'Montserrat-Regular',
													fontSize: '20px',
													color: APP_CONFIG.mainCollors.primary,
													marginTop: '35px',
												}}
											>
												R$
												{parseFloat(
													userData.saldo.valor
												).toLocaleString('pt-br', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</Typography>
										)}

									<Box
										style={{
											marginTop: '30px',
											marginBottom: '25px',
										}}
									>
										<Button
											style={{
												backgroundColor:
													APP_CONFIG.mainCollors.primary,
												borderRadius: '27px',
												marginTop: '15px',
											}}
											color="purple"
											onClick={() => {
												setModalRetirada(true);
												setTextRetirada(true);
												setValorRetirada(null);
											}}
										>
											<Typography
												style={{
													fontFamily: 'Montserrat-Regular',
													fontSize: '16px',
													color: 'white',
												}}
											>
												Retirada
											</Typography>
										</Button>
									</Box>
									<Dialog
										open={modalRetirada}
										onClose={() => {
											{
												setModalRetirada(false);
												setTextRetirada(false);
												setValorRetirada(null);
											}
										}}
										aria-labelledby="form-dialog-title"
									>
										<DialogTitle
											style={{
												color: APP_CONFIG.mainCollors.primary,
											}}
											id="form-dialog-title"
										>
											{textRetirada
												? 'Método de retirada'
												: 'Método de transferência'}
										</DialogTitle>
										<form /* onSubmit={(e) => handleContinuar()} */>
											<DialogContent>
												{textRetirada ? (
													<>
														<DialogContentText
															style={{
																color: APP_CONFIG.mainCollors
																	.primary,
															}}
														>
															Selecione um valor:
														</DialogContentText>
														<CurrencyInput
															placeHolder="R$0,00"
															className={classes.currencyInput}
															decimalSeparator=","
															thousandSeparator="."
															prefix="R$ "
															value={valorRetirada}
															onChangeEvent={(
																event,
																maskedvalue,
																floatvalue
															) => {
																setValorRetirada(floatvalue);
															}}
														/>
													</>
												) : null}

												<DialogContentText
													style={{
														color: APP_CONFIG.mainCollors.primary,
														marginTop: '10px',
													}}
												>
													{textRetirada
														? 'Escolha como fazer a retirada:'
														: 'Escolha como fazer a transferência:'}
												</DialogContentText>
												<Box>
													<Box
														style={{
															display: 'flex',
															alignItems: 'center',
															alignSelf: 'center',
															marginTop: '30px',
														}}
													>
														<Box>
															<Select
																style={{ width: '200px' }}
																value={tipoTransferencia}
																label="Tipo"
																onChange={(e) =>
																	setTipoTransferencia(
																		e.target.value
																	)
																}
															>
																<MenuItem
																	value={'Pix'}
																	style={{
																		color: APP_CONFIG
																			.mainCollors.secondary,
																		fontFamily:
																			'Montserrat-Regular',
																	}}
																>
																	Pix
																</MenuItem>
																<MenuItem
																	value={'TED'}
																	style={{
																		color: APP_CONFIG
																			.mainCollors.secondary,
																		fontFamily:
																			'Montserrat-Regular',
																	}}
																>
																	TED
																</MenuItem>
																<MenuItem
																	value={'P2P'}
																	style={{
																		color: APP_CONFIG
																			.mainCollors.secondary,
																		fontFamily:
																			'Montserrat-Regular',
																	}}
																>
																	P2P
																</MenuItem>
															</Select>
														</Box>
													</Box>
												</Box>
											</DialogContent>
											<DialogActions>
												<Button
													onClick={() => {
														{
															setModalRetirada(false);
															setTextRetirada(false);
															setValorRetirada(null);
														}
													}}
													color="primary"
												>
													Cancelar
												</Button>
												<Button
													onClick={() => {
														{
															setModalRetirada(false);
															handleContinuar();
														}
													}}
													color="primary"
													/* type="submit" */
												>
													Continuar
												</Button>
											</DialogActions>
										</form>
									</Dialog>
								</Box>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Box
									style={{
										display: 'flex',
										backgroundColor:
											APP_CONFIG.mainCollors.backgrounds,
										/* height: '100px', */
										borderRadius: '17px',
										flexDirection: 'column',

										alignItems: 'center',
									}}
								>
									<Typography
										style={{
											fontFamily: 'Montserrat-Regular',
											fontSize: '16px',
											color: APP_CONFIG.mainCollors.primary,
											marginTop: '30px',
										}}
									>
										Transferências
									</Typography>
									<Box
										style={{
											width: '80%',
											height: '1px',
											backgroundColor:
												APP_CONFIG.mainCollors.primary,
										}}
									/>
									<Typography
										style={{
											fontFamily: 'Montserrat-Regular',
											fontSize: '13px',
											color: APP_CONFIG.mainCollors.primary,
											marginTop: '10px',
										}}
									>
										Quanto você quer transferir?
									</Typography>

									<CurrencyInput
										placeHolder="R$0,00"
										style={{
											alignSelf: 'center',
											textAlign: 'center',
											height: 45,
											fontSize: '20px',
											borderWidth: '1px !important',
											borderRadius: 27,
											border: 'none',
											color: APP_CONFIG.mainCollors.primary,
											backgroundColor: 'transparent',
											fontFamily: 'Montserrat-Regular',
										}}
										decimalSeparator=","
										thousandSeparator="."
										prefix="R$ "
										value={textRetirada ? '' : valorRetirada}
										onChangeEvent={(
											event,
											maskedvalue,
											floatvalue
										) => {
											setValorRetirada(floatvalue);
										}}
									/>
									<Box
										style={{
											marginTop: '30px',
											marginBottom: '30px',
										}}
									>
										<Button
											style={{
												backgroundColor:
													APP_CONFIG.mainCollors.primary,
												borderRadius: '27px',
											}}
											color="purple"
											onClick={() => {
												setModalRetirada(true);
												setTextRetirada(false);
											}}
										>
											<Typography
												style={{
													fontFamily: 'Montserrat-Regular',
													fontSize: '16px',
													color: 'white',
												}}
											>
												Transferir
											</Typography>
										</Button>
									</Box>
								</Box>
							</Grid>
							<Grid item sm={5} xs={12}>
								<Box
									style={{
										display: 'flex',
										backgroundColor: APP_CONFIG.mainCollors.primary,
										/* height: '100px', */
										borderRadius: '17px',
										flexDirection: 'column',

										alignItems: 'center',
									}}
								>
									<Typography
										style={{
											fontFamily: 'Montserrat-Regular',
											fontSize: '16px',
											color: 'white',
											marginTop: '30px',
										}}
									>
										Entradas e saídas
									</Typography>
									<Box
										style={{
											width: '80%',
											height: '1px',
											backgroundColor: 'white',
										}}
									/>

									<Box
										style={{
											marginTop: '140px',
											marginBottom: '30px',
										}}
									>
										{/* <CustomLineChart /> */}
									</Box>
								</Box>
							</Grid>
						</Grid>
						<Grid container spacing={2} style={{ marginTop: '0px' }}>
							<Grid item sm={5} xs={12}>
								<Box
									style={{
										display: 'flex',
										backgroundColor: APP_CONFIG.mainCollors.primary,

										borderRadius: '17px',
										flexDirection: 'column',

										alignItems: 'center',
										width: '100%',
									}}
								>
									<Box
										style={{
											display: 'flex',
											justifyContent: 'space-around',
											width: '100%',
											marginTop: '10px',
										}}
									>
										<Box style={{ width: '20px' }} />
										<Box
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
											}}
										>
											<Typography
												style={{
													fontFamily: 'Montserrat-Regular',
													fontSize: '18px',
													color: 'white',
													marginTop: '0px',
												}}
											>
												Extrato
											</Typography>
											<Typography
												style={{
													fontFamily: 'Montserrat-Regular',
													fontSize: '16px',
													color: 'white',
													marginTop: '30px',
												}}
											>
												Últimas movimentações
											</Typography>
											<Box
												style={{
													width: '80%',
													height: '1px',
													backgroundColor: 'white',
												}}
											/>
										</Box>

										<Button
											style={{
												borderRadius: '60px',
												backgroundColor: 'white',
												minWidth: '20px',
												height: '40px',
												display: 'flex',
											}}
											onClick={() => history.push('extrato')}
										>
											<ArticleIcon
												style={{
													color: APP_CONFIG.mainCollors.primary,
													fontSize: '30px',
												}}
											/>
										</Button>
									</Box>
									<Box
										style={{
											width: '100%',
											/* height: '400px', */
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										{extrato.data && extrato.data.length > 0
											? extrato.data.map((item, index) =>
													index === 0 ? (
														<>
															<Typography
																style={{
																	fontFamily:
																		'Montserrat-Regular',
																	fontSize: '16px',
																	color: 'white',
																	marginTop: '30px',
																	marginLeft: '30px',
																}}
															>
																{moment
																	.utc(item.data)
																	.format('DD MMMM (dddd)')}
															</Typography>
															<Box
																style={{
																	width: '100%',
																	display: 'flex',
																	flexDirection: 'column',
																	alignItems: 'center',
																}}
															>
																<Box
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		borderRadius: '17px',
																		backgroundColor:
																			APP_CONFIG.mainCollors
																				.extratoHome,
																		alignSelf: 'center',
																		width: '90%',
																		marginBottom: '20px',
																	}}
																>
																	{item.items &&
																	item.items.length > 0
																		? item.items.map(
																				(
																					subItem,
																					index
																				) => (
																					<>
																						{index ===
																						0 ? (
																							<Box
																								style={{
																									display:
																										'flex',
																									flexDirection:
																										'column',
																								}}
																							>
																								<Box
																									style={{
																										display:
																											'flex',
																										justifyContent:
																											'space-around',
																									}}
																								>
																									<Typography
																										style={{
																											fontFamily:
																												'Montserrat-Regular',
																											fontSize:
																												'16px',
																											color: 'white',
																											marginTop:
																												'30px',
																										}}
																									>
																										{
																											subItem.Description
																										}
																									</Typography>
																									<Box>
																										<Typography
																											style={{
																												fontFamily:
																													'Montserrat-Regular',
																												fontSize:
																													'16px',
																												color: 'white',

																												marginTop:
																													'30px',
																											}}
																										>
																											R${' '}
																											{parseFloat(
																												subItem.EntryValue
																											).toLocaleString(
																												'pt-br',
																												{
																													minimumFractionDigits: 2,
																													maximumFractionDigits: 2,
																												}
																											)}
																										</Typography>
																									</Box>
																								</Box>
																								<Divider
																									style={{
																										backgroundColor:
																											'white',
																										width: '90%',
																										alignSelf:
																											'center',
																									}}
																								/>
																							</Box>
																						) : index !==
																						  item.items
																								.length -
																								1 ? (
																							<>
																								<Box
																									style={{
																										display:
																											'flex',
																										alignItems:
																											'center',
																										marginTop:
																											'10px',
																									}}
																								>
																									<Grid
																										container
																										spacing={
																											0
																										}
																										style={{
																											marginTop:
																												'10px',
																											display:
																												'flex',
																											textAlign:
																												'center',
																										}}
																									>
																										<Grid
																											style={{
																												display:
																													'flex',
																												justifyContent:
																													'center',
																												textAlign:
																													'center',
																												alignItems:
																													'center',
																											}}
																											item
																											sm={
																												2
																											}
																											xs={
																												12
																											}
																										>
																											<Box
																												style={{
																													alignSelf:
																														'center',
																													backgroundColor:
																														APP_CONFIG
																															.mainCollors
																															.primary,
																													display:
																														'flex',
																													flexDirection:
																														'column',
																													height:
																														'50px',
																													width: '50px',
																													borderRadius:
																														'32px',
																													alignItems:
																														'center',
																													justifyContent:
																														'center',
																												}}
																											>
																												<PersonIcon
																													style={{
																														color: 'white',
																														fontSize:
																															'30px',
																													}}
																												/>
																											</Box>
																										</Grid>
																										<Grid
																											item
																											sm={
																												10
																											}
																											xs={
																												12
																											}
																										>
																											<Typography
																												style={{
																													fontFamily:
																														'Montserrat-Regular',
																													fontSize:
																														'16px',
																													color: 'white',
																												}}
																											>
																												{
																													subItem.Details
																												}
																											</Typography>
																										</Grid>
																									</Grid>
																								</Box>
																								<Grid
																									container
																									spacing={
																										2
																									}
																									style={{
																										marginTop:
																											'0px',
																										display:
																											'flex',
																										textAlign:
																											'center',
																									}}
																								>
																									<Grid
																										item
																										sm={
																											6
																										}
																										xs={
																											12
																										}
																									>
																										{subItem.OperationType ===
																										6 ? (
																											<Typography
																												style={{
																													fontFamily:
																														'Montserrat-Regular',
																													fontSize:
																														'16px',
																													color: 'white',
																												}}
																											>
																												Transferência
																											</Typography>
																										) : subItem.OperationType ===
																										  0 ? (
																											<Typography
																												style={{
																													fontFamily:
																														'Montserrat-Regular',
																													fontSize:
																														'16px',
																													color: 'white',
																												}}
																											>
																												P2P
																											</Typography>
																										) : subItem.OperationType ===
																										  3 ? (
																											<Typography
																												style={{
																													fontFamily:
																														'Montserrat-Regular',
																													fontSize:
																														'16px',
																													color: 'white',
																												}}
																											>
																												TED
																											</Typography>
																										) : subItem.OperationType ===
																										  2 ? (
																											<Typography
																												style={{
																													fontFamily:
																														'Montserrat-Regular',
																													fontSize:
																														'16px',
																													color: 'white',
																												}}
																											>
																												Pagamento
																												de
																												conta
																											</Typography>
																										) : subItem.OperationType ===
																										  23 ? (
																											<Typography
																												style={{
																													fontFamily:
																														'Montserrat-Regular',
																													fontSize:
																														'16px',
																													color: 'white',
																												}}
																											>
																												Saque
																												digital
																											</Typography>
																										) : subItem.OperationType ===
																										  37 ? (
																											<Typography
																												style={{
																													fontFamily:
																														'Montserrat-Regular',
																													fontSize:
																														'16px',
																													color: 'white',
																												}}
																											>
																												Pre
																												débito
																												de
																												recarga
																											</Typography>
																										) : subItem.OperationType ===
																										  40 ? (
																											<Typography
																												style={{
																													fontFamily:
																														'Montserrat-Regular',
																													fontSize:
																														'16px',
																													color: 'white',
																												}}
																											>
																												Pix
																												pagamento
																											</Typography>
																										) : subItem.OperationType ===
																										  41 ? (
																											<Typography
																												style={{
																													fontFamily:
																														'Montserrat-Regular',
																													fontSize:
																														'16px',
																													color: 'white',
																												}}
																											>
																												Pix
																												recebido
																											</Typography>
																										) : null}
																									</Grid>
																									<Grid
																										item
																										sm={
																											6
																										}
																										xs={
																											12
																										}
																									>
																										<Typography
																											style={{
																												fontFamily:
																													'Montserrat-Regular',
																												fontSize:
																													'16px',
																												color: 'white',
																											}}
																										>
																											{moment
																												.utc(
																													subItem.EntryDate
																												)
																												.format(
																													'h:mm'
																												)}
																										</Typography>
																									</Grid>
																								</Grid>
																								<Grid
																									container
																									spacing={
																										2
																									}
																									style={{
																										marginTop:
																											'0px',
																										display:
																											'flex',
																										textAlign:
																											'center',
																									}}
																								>
																									<Grid
																										item
																										sm={
																											6
																										}
																										xs={
																											12
																										}
																									>
																										<Typography
																											style={{
																												fontFamily:
																													'Montserrat-Regular',
																												fontSize:
																													'16px',
																												color: 'white',
																											}}
																										>
																											Valor
																										</Typography>
																									</Grid>
																									<Grid
																										item
																										sm={
																											6
																										}
																										xs={
																											12
																										}
																									>
																										<Typography
																											style={{
																												fontFamily:
																													'Montserrat-Regular',
																												fontSize:
																													'16px',
																												color:
																													subItem.EntryValue >=
																													0
																														? '#009838'
																														: '#E50912',
																												marginBottom:
																													'10px',
																											}}
																										>
																											R${' '}
																											{parseFloat(
																												subItem.EntryValue
																											).toLocaleString(
																												'pt-br',
																												{
																													minimumFractionDigits: 2,
																													maximumFractionDigits: 2,
																												}
																											)}
																										</Typography>
																									</Grid>
																								</Grid>
																								<Divider
																									style={{
																										backgroundColor:
																											'white',
																										width: '90%',
																										alignSelf:
																											'center',
																									}}
																								/>
																							</>
																						) : null}
																						{index ===
																						item.items
																							.length -
																							1 ? (
																							<Box
																								style={{
																									display:
																										'flex',
																									flexDirection:
																										'column',
																									marginBottom:
																										'20px',
																								}}
																							>
																								<Box
																									style={{
																										display:
																											'flex',
																										justifyContent:
																											'space-around',
																									}}
																								>
																									<Typography
																										style={{
																											fontFamily:
																												'Montserrat-Regular',
																											fontSize:
																												'16px',
																											color: 'white',
																											marginTop:
																												'30px',
																										}}
																									>
																										{
																											subItem.Description
																										}
																									</Typography>
																									<Box>
																										<Typography
																											style={{
																												fontFamily:
																													'Montserrat-Regular',
																												fontSize:
																													'16px',
																												color: 'white',

																												marginTop:
																													'30px',
																											}}
																										>
																											R${' '}
																											{parseFloat(
																												subItem.EntryValue
																											).toLocaleString(
																												'pt-br',
																												{
																													minimumFractionDigits: 2,
																													maximumFractionDigits: 2,
																												}
																											)}
																										</Typography>
																									</Box>
																								</Box>
																							</Box>
																						) : null}
																					</>
																				)
																		  )
																		: null}
																</Box>
															</Box>
														</>
													) : null
											  )
											: null}
									</Box>
									{/* <CustomTable
										columns={columns ? columns : null}
										data={extrato.data}
									/> */}
								</Box>
							</Grid>
							<Grid item sm={7} xs={12}>
								{listaBanner &&
								listaBanner.data &&
								listaBanner.data.length > 0 ? (
									<Carousel
										autoPlay
										showThumbs={false}
										showArrows={false}
										showIndicators={true}
										showStatus={false}
										interval={4000}
										infiniteLoop
									>
										{listaBanner.data.map((item) => (
											<CardMedia
												style={{
													display: 'flex',
													backgroundColor: '#ED757D',
													height: '300px',
													borderRadius: '17px',
													flexDirection: 'column',

													alignItems: 'center',
													'&:hover': {
														cursor: 'pointer',
													},
												}}
												image={item.imagem}
												onClick={() =>
													item.url ? window.open(item.url) : null
												}
											/>
										))}
									</Carousel>
								) : (
									<Box
										style={{
											display: 'flex',
											backgroundColor: '#ED757D',
											height: '300px',
											borderRadius: '17px',
											flexDirection: 'column',

											alignItems: 'center',
										}}
									/>
								)}

								<Box style={{ display: 'flex' }}>
									<Grid
										container
										spacing={2}
										style={{ marginTop: '0px' }}
									>
										<Grid item sm={3} xs={12}>
											<Box
												onClick={() =>
													history.push('lista-pagamentos')
												}
											>
												<CustomCard
													icon="pagamentos"
													title="Pagamentos"
												/>
											</Box>
										</Grid>
										<Grid item sm={3} xs={12}>
											<Box
												onClick={() => history.push('extratoTED')}
											>
												<CustomCard
													icon="transferencias"
													title="Transferências"
												/>
											</Box>
										</Grid>
										<Grid item sm={3} xs={12}>
											<Box onClick={() => history.push('pix')}>
												<CustomCard icon="pix" title="Pix" />
											</Box>
										</Grid>
										<Grid item sm={3} xs={12}>
											<Box
												onClick={() =>
													history.push('lista-boletos')
												}
											>
												<CustomCard
													icon="boletos"
													title="Boletos"
												/>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

/* import {
	Box,
	Grid,
	LinearProgress,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import {
	getContasAction,
	getGraficoContaBarDashboardAction,
	getGraficoContaLineDashboardAction,
	getResumoContaDashboardAction,
	loadPermissao,
	postAuthMeAction,
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CustomBarChart from '../../components/CustomBarChart/CustomBarChart';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomCard from '../../components/CustomCard/CustomCard';
import CustomLineChart from '../../components/CustomLineChart/CustomLineChart';
import CustomTable from '../../components/CustomTable/CustomTable';
import ItaDash4 from '../../assets/ItaDash4.svg';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',

		height: '100%',
	},
	headerContainer: {
		display: 'flex',
		width: '100%',

		flexDirection: 'column',
	},

	contadorStyle: {
		display: 'flex',
		fontSize: '30px',
		fontFamily: 'Montserrat-SemiBold',
	},

	pageTitle: {
		color: '#9D9CC6',
		fontFamily: 'Montserrat-SemiBold',
	},

	cardContainer: {
		display: 'flex',
		width: '100%',
		height: '100%',
		justifyContent: 'space-between',
	},

	bodyContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		marginTop: '10px',
	},
}));

const columns = [
	{ headerText: 'Nome', key: 'nome' },
	{ headerText: 'Tipo', key: 'tipo' },
	{
		headerText: 'Status',
		key: 'status',
		CustomValue: (value) => {
			if (value === 'pending') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#F1E3D4',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: 'orange', width: '100%' }}>
								PENDENTE
							</Typography>
						</Box>
					</Box>
				);
			}
			if (value === 'active') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#C9DBF2',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: '#75B1ED', width: '100%' }}>
								ATIVO
							</Typography>
						</Box>
					</Box>
				);
			}
			if (value === 'enabled') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#C9DBF2',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: '#75B1ED', width: '100%' }}>
								ATIVO
							</Typography>
						</Box>
					</Box>
				);
			}
			if (value === 'approved') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#C9ECE7',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: '#00B57D', width: '100%' }}>
								APROVADO
							</Typography>
						</Box>
					</Box>
				);
			}
			if (value === 'denied') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#ECC9D2',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: '#ED757D', width: '100%' }}>
								NEGADO
							</Typography>
						</Box>
					</Box>
				);
			}
		},
	},
	{ headerText: 'Número do Documento', key: 'numero_documento' },
	{ headerText: 'Documento', key: 'documento' },
];

const Dashboard = () => {
	const history = useHistory();
	const classes = useStyles();
	const token = useAuth();

	const [page, setPage] = useState(1);

	const dispatch = useDispatch();
	const contadores = useSelector((state) => state.contadores);

	useEffect(() => {
		dispatch(getResumoContaDashboardAction(token));
	}, []);

	const listaContas = useSelector((state) => state.contas);
	useEffect(() => {
		dispatch(
			getContasAction(
				token,
				page,

				'',
				'',
				5
			)
		);
	}, [token, page]);

	const handleVerTudo = () => {
		const path = generatePath('/dashboard/lista-de-contas');
		history.push(path);
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.headerContainer}>
				<Box>
					<Typography className={classes.pageTitle}>DASHBOARD</Typography>
				</Box>

				<Box
					style={{
						display: 'flex',

						marginTop: '20px',
						marginLeft: '-12px',
					}}
				>
					<CustomCard
						text="Contas pendentes"
						
					>
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_pendente}
							</Typography>
						</Box>
					</CustomCard>

					<CustomCard text="Contas aprovadas" aprovada>
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_aprovado}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Contas ativas">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_ativo}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Contas rejeitadas" rejeitada>
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_rejeitado}
							</Typography>
						</Box>
					</CustomCard>
				</Box>

				<Box
					style={{
						display: 'flex',

						marginLeft: '-12px',
					}}
				>
					<CustomCard text="Frequência diária">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.frequencia_quantidade_diaria}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Cadastro Pessoa Física">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_pessoa_fisica}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Cadastro Pessoa Jurídica">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_pessoa_juridica}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Total de Contas">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_total}
							</Typography>
						</Box>
					</CustomCard>
				</Box>
			</Box>
			<Box className={classes.bodyContainer}>
				<Box display="flex">
					<Grid container>
						<Grid xs={8}>
							<CustomLineChart />
						</Grid>
						<Grid xs={4}>
							<CustomBarChart />
						</Grid>
					</Grid>
				</Box>
				<Box display="flex" style={{ height: '100%', marginTop: '40px' }}>
					<Grid container>
						<Grid xs={12}>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',

									height: '75px',
									backgroundColor: APP_CONFIG.mainCollors.backgrounds,
									borderTopRightRadius: 27,
									borderTopLeftRadius: 27,
								}}
							>
								<Typography
									style={{
										color: '#9D9CC6',
										fontFamily: 'Montserrat-SemiBold',
										marginTop: '20px',
										alignSelf: 'center',
										marginLeft: '30px',
									}}
								>
									CONTAS RECENTES
								</Typography>

								<Box
									style={{
										marginTop: '20px',
										marginRight: '10px',
									}}
								>
									<CustomButton
										size="small"
										color="purple"
										onClick={handleVerTudo}
									>
										VER TUDO
									</CustomButton>
								</Box>
							</Box>
							<Box style={{ marginBottom: '40px', width: '100%' }}>
								{listaContas.data && listaContas.per_page ? (
									<CustomTable
										boxShadowTop={true}
										columns={columns}
										data={listaContas.data}
									/>
								) : null}
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export default Dashboard;
 */
