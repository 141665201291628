/* eslint-disable no-lone-blocks */

import '../../fonts/Montserrat-SemiBold.otf';

import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	LinearProgress,
	Menu,
	MenuItem,
	Select,
	TextField,
	Typography,
	makeStyles,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import {
	getAprovarContaAction,
	getContasAction,
	getContasExportAction,
	getFinalizarCadastroContaAction,
	getReenviarTokenUsuarioAction,
	loadPermissao,
	postAuthMeAction,
	postBlackListSelfieAction,
	postBloquearDeviceAdmAction,
	postDesbloquearDeviceAdmAction,
	postUserBloquearDesbloquearAction,
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomTable from '../../components/CustomTable/CustomTable';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { Pagination } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewListIcon from '@material-ui/icons/ViewList';
import { filters_gerenciar_contas } from '../../constants/localStorageStrings';
import { isEqual } from 'lodash';
import { postBloquearDeviceAdm } from '../../services/services';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';
import { APP_CONFIG } from '../../constants/config';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginBottom: '25px',
	},
	tableContainer: { marginTop: '1px' },
	pageTitle: {
		color: '#9D9CC6',
		fontFamily: 'Montserrat-SemiBold',
	},
}));

const ListaDeContas = () => {
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
		id: '',
		seller: '',
		status: ' ',
		numero_documento: '',
		tipo: ' ',
	});

	const [filtersComparation] = useState({
		like: '',
		order: '',
		mostrar: '',
		id: '',
		seller: '',
		status: ' ',
		numero_documento: '',
		tipo: ' ',
	});

	const debouncedLike = useDebounce(filters.like, 800);
	const debouncedId = useDebounce(filters.id, 800);
	const debouncedSeller = useDebounce(filters.seller, 800);
	const debouncedNumeroDocumento = useDebounce(filters.numero_documento, 800);

	const [loading, setLoading] = useState(false);
	const token = useAuth();
	const classes = useStyles();
	const [page, setPage] = useState(1);
	const history = useHistory();
	const dispatch = useDispatch();
	const [permissoes, setPermissoes] = useState([]);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		dispatch(
			getContasAction(
				token,
				page,
				debouncedLike,
				filters.order,
				filters.mostrar,
				debouncedId,
				debouncedSeller,
				filters.status,
				debouncedNumeroDocumento,
				filters.tipo
			)
		);
	}, [
		page,
		debouncedLike,
		filters.order,
		filters.mostrar,
		debouncedId,
		debouncedSeller,
		filters.status,
		debouncedNumeroDocumento,
		filters.tipo,
	]);

	const columns = [
		{ headerText: 'E-mail', key: 'email' },
		{ headerText: 'Nome', key: 'nome' },
		{ headerText: 'Tipo', key: 'tipo' },
		{
			headerText: 'Status',
			key: 'status',
			CustomValue: (value) => {
				if (
					permissoes.includes(
						'Atendimento - Consulta de status da conta'
					) ||
					permissoes.includes('Administrador - Acesso total')
				) {
					if (value === 'pending') {
						return (
							<Box
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%',
								}}
							>
								<Box
									style={{
										borderRadius: 32,
										backgroundColor: '#F1E3D4',
										maxWidth: '120px',
										padding: '5px',
									}}
								>
									<Typography
										style={{ color: 'orange', width: '100%' }}
									>
										PENDENTE
									</Typography>
								</Box>
							</Box>
						);
					}
					if (value === 'active') {
						return (
							<Box
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%',
								}}
							>
								<Box
									style={{
										borderRadius: 32,
										backgroundColor: '#C9DBF2',
										maxWidth: '120px',
										padding: '5px',
									}}
								>
									<Typography
										style={{ color: '#75B1ED', width: '100%' }}
									>
										ATIVO
									</Typography>
								</Box>
							</Box>
						);
					}
					if (value === 'enabled') {
						return (
							<Box
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%',
								}}
							>
								<Box
									style={{
										borderRadius: 32,
										backgroundColor: '#C9DBF2',
										maxWidth: '120px',
										padding: '5px',
									}}
								>
									<Typography
										style={{ color: '#75B1ED', width: '100%' }}
									>
										HABILITADO
									</Typography>
								</Box>
							</Box>
						);
					}
					if (value === 'approved') {
						return (
							<Box
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%',
								}}
							>
								<Box
									style={{
										borderRadius: 32,
										backgroundColor: '#C9ECE7',
										maxWidth: '120px',
										padding: '5px',
									}}
								>
									<Typography
										style={{ color: '#00B57D', width: '100%' }}
									>
										APROVADO
									</Typography>
								</Box>
							</Box>
						);
					}
					if (value === 'denied') {
						return (
							<Box
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%',
								}}
							>
								<Box
									style={{
										borderRadius: 32,
										backgroundColor: '#ECC9D2',
										maxWidth: '120px',
										padding: '5px',
									}}
								>
									<Typography
										style={{ color: '#ED757D', width: '100%' }}
									>
										NEGADO
									</Typography>
								</Box>
							</Box>
						);
					}
				} else {
					return null;
				}
			},
		},
		{ headerText: 'Número do Documento', key: 'numero_documento' },
		{ headerText: 'Documento', key: 'documento' },
		{ headerText: 'Contato', key: 'celular' },
		{
			headerText: 'Segurança',
			key: '',
			FullObject: ({ user }) => {
				if (user) {
					if (user.verificar_device_bloqueado) {
						return (
							<Typography style={{ color: 'red', fontSize: '0.7rem' }}>
								Dispositivo bloqueado
								<br />
								Aguardando Verificação
							</Typography>
						);
					}
					if (user.verificar_device_alterado) {
						return (
							<Typography style={{ color: 'red', fontSize: '0.7rem' }}>
								Dispositivo alterado
								<br />
								Aguardando Verificação
							</Typography>
						);
					}

					if (user.aguardando_confirmacao_device) {
						return (
							<Typography
								style={{ color: 'yellow', fontSize: '0.7rem' }}
							>
								Aguardando Confirmação
							</Typography>
						);
					}

					if (
						!user.verificar_device_alterado &&
						!user.verificar_device_bloqueado &&
						!user.aguardando_confirmacao_device
					) {
						return <CheckIcon style={{ color: 'green' }} />;
					}
				}
			},
		},
		{ headerText: '', key: 'menu' },
	];

	const listaContas = useSelector((state) => state.contas);
	/* 	const allContas = useSelector((state) => state.allContas); */
	const me = useSelector((state) => state.me);
	const userPermissao = useSelector((state) => state.userPermissao);

	/* useEffect(() => {
		if (listaContas.total && listaContas.total > 0) {
			dispatch(getAllContasAction(token, listaContas.total));
		}
	}, [listaContas]); */

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	useEffect(() => {
		dispatch(postAuthMeAction(token));
	}, []);

	useEffect(() => {
		if (me.id !== undefined) {
			dispatch(loadPermissao(token, me.id));
		}
	}, [me.id]);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	const handleExportar = async () => {
		setLoading(true);
		toast.warning('A exportação pode demorar um pouco, por favor aguarde...');
		const res = await dispatch(
			getContasExportAction(
				token,
				'',
				page,
				debouncedLike,
				filters.id,
				filters.seller,
				filters.status,
				filters.numero_documento,
				filters.tipo,
				filters.order,
				filters.mostrar
			)
		);
		if (res && res.url !== undefined) {
			window.open(`${res.url}`, '', '');
		} else {
		}
	};

	const Editar = (row) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const [isModalBloquearOpen, setIsModalBloquearOpen] = useState(false);
		const [bloquerDispositivoData, setBloquerDispositivoData] = useState({
			conta_id: '',
			descricao: '',
		});
		const [bloquearErrors, setBloquearErrors] = useState({});
		const [isModalDesbloquearOpen, setIsModalDesbloquearOpen] =
			useState(false);
		const [desbloquerId, setDesbloquerId] = useState({
			conta_id: '',
			verificacao_seguranca: {},
		});
		const [isModalBlackListOpen, setIsModalBlackListOpen] = useState(false);
		const [negarCadastroId, setNegarCadastroId] = useState('');

		const handleEditarUsuario = () => {
			const path = generatePath('/dashboard/editar-conta/:id/editar', {
				id: row.row.id,
			});
			{
				permissoes.includes(
					'Atendimento - Consulta de dados cadastrais(E-mail, Telefone, Endereço e CPF)'
				) || permissoes.includes('Administrador - Acesso total')
					? history.push(path)
					: toast.error('Permissão não concedida');
			}
		};

		/* const handleGerenciarUsuario = () => {
			const path = generatePath('/dashboard/gerenciar-contas/:id/listas', {
				id: row.row.id,
			});
			history.push(path);
		}; */

		const handleGerenciarUsuario = () => {
			const path = generatePath('/dashboard/gerenciar-contas/:id/listas', {
				id: row.row.id,
			});
			{
				permissoes.includes(
					'Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação e editar'
				) || permissoes.includes('Administrador - Acesso total')
					? history.push(path)
					: toast.error('Permissão não concedida');
			}
		};
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleAprovar = async (row) => {
			if (
				permissoes.includes(
					'Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação e editar'
				) ||
				permissoes.includes('Administrador - Acesso total')
			) {
				const resAprovar = await dispatch(
					getAprovarContaAction(token, row.row.id)
				);
				if (resAprovar && resAprovar.success === true) {
					toast.success('Usuário aprovado com sucesso!');
					await dispatch(
						getContasAction(
							token,
							page,
							debouncedLike,
							filters.order,
							filters.mostrar,
							filters.id,
							filters.seller,
							filters.status,
							filters.numero_documento,
							filters.tipo
						)
					);
				} else {
					toast.error('Falha ao aprovar usuário');
				}
				setAnchorEl(null);
			} else {
				toast.error('Sem permissão para acessar essa funcionalidade');
			}
		};

		const handleReenviarToken = async (row) => {
			if (
				permissoes.includes(
					'Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação e editar'
				) ||
				permissoes.includes('Administrador - Acesso total')
			) {
				if (row.row.user && row.row.user.id) {
					const resAprovar = await dispatch(
						getReenviarTokenUsuarioAction(token, row.row.user.id)
					);
					if (resAprovar) {
						toast.error('Falha ao reenviar token');
					} else {
						toast.success('Token reenviado com sucesso!');
						await dispatch(
							getContasAction(
								token,
								page,
								debouncedLike,
								filters.order,
								filters.mostrar,
								filters.id,
								filters.seller,
								filters.status,
								filters.numero_documento,
								filters.tipo
							)
						);
					}
					setAnchorEl(null);
				} else {
					toast.error('Usuário negado');
					return;
				}
			} else {
				toast.error('Sem permissão para acessar essa funcionalidade');
			}
		};

		const handleBloquearDesbloquear = async (row) => {
			if (row.row.user && row.row.user.id) {
				const resAprovar = await dispatch(
					postUserBloquearDesbloquearAction(token, row.row.user.id)
				);
				if (resAprovar) {
					toast.error('Falha ao reenviar token');
				} else {
					if (row.row.user && row.row.user.bloqueado === false) {
						toast.success('Usuário bloqueado!');
						await dispatch(
							getContasAction(
								token,
								page,
								debouncedLike,
								filters.order,
								filters.mostrar,
								filters.id,
								filters.seller,
								filters.status,
								filters.numero_documento,
								filters.tipo
							)
						);
					} else {
						await dispatch(
							getContasAction(
								token,
								page,
								debouncedLike,
								filters.order,
								filters.mostrar,
								filters.id,
								filters.seller,
								filters.status,
								filters.numero_documento,
								filters.tipo
							)
						);
						toast.success('Usuário desbloqueado!');
					}
				}
				setAnchorEl(null);
			} else {
				toast.error('Usuário negado');
				return;
			}
		};

		const handleFinalizar = async (row) => {
			const resAprovar = await dispatch(
				getFinalizarCadastroContaAction(token, row.row.id)
			);
			if (resAprovar && resAprovar.success === true) {
				toast.success(
					'Solicitação de finalizar cadastro enviado com sucesso!'
				);
				await dispatch(
					getContasAction(
						token,
						page,
						debouncedLike,
						filters.order,
						filters.mostrar,
						filters.id,
						filters.seller,
						filters.status,
						filters.numero_documento,
						filters.tipo
					)
				);
			} else {
				toast.error('Falha ao enviar solicitação de finalizar cadastro');
			}
			setAnchorEl(null);
		};

		const handleBloquearDispositivo = async (row) => {
			const { success, errors, status } = await dispatch(
				postBloquearDeviceAdmAction(
					token,
					bloquerDispositivoData.conta_id,
					bloquerDispositivoData.descricao
				)
			);
			if (success) {
				toast.success('Dispositivo bloqueado com sucesso!');
				setFilters(filtersComparation);
			} else {
				setBloquearErrors({ ...errors });
				toast.error(`Erro ao bloquear dispositivo: ${status}`);
			}
		};

		const handleDesbloquearDispositivo = async (row) => {
			const { success, status } = await dispatch(
				postDesbloquearDeviceAdmAction(token, desbloquerId.conta_id)
			);
			if (success) {
				toast.success('Dispositivo desbloqueado com sucesso!');
				setFilters(filtersComparation);
			} else {
				toast.error(`Erro ao bloquear dispositivo: ${status}`);
			}
		};

		const handleNegarCadastro = async (row) => {};

		return (
			<Box>
				<IconButton
					style={{ height: '15px', width: '10px' }}
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<SettingsIcon
						style={{
							borderRadius: 33,
							fontSize: '35px',
							backgroundColor: APP_CONFIG.mainCollors.primary,
							color: 'white',
						}}
					/>
				</IconButton>
				<Menu
					onClick={() => {}}
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem
						onClick={() => handleEditarUsuario(row)}
						style={{ color: APP_CONFIG.mainCollors.secondary }}
					>
						Editar
					</MenuItem>
					<MenuItem
						onClick={() => handleAprovar(row)}
						style={{ color: APP_CONFIG.mainCollors.secondary }}
					>
						Aprovar
					</MenuItem>
					{row.row.user && row.row.user.bloqueado === false ? (
						<MenuItem
							onClick={() => handleBloquearDesbloquear(row)}
							style={{ color: APP_CONFIG.mainCollors.secondary }}
						>
							Bloquear
						</MenuItem>
					) : (
						<MenuItem
							onClick={() => handleBloquearDesbloquear(row)}
							style={{ color: APP_CONFIG.mainCollors.secondary }}
						>
							Desbloquear
						</MenuItem>
					)}
					{/* <MenuItem onClick={() => handleBloquearDesbloquear(row)}>
						{row.row.user && row.row.user.bloqueado === 'false' ? (
							<Typography>Bloquear</Typography>
						) : (
							<Typography>Desbloquear</Typography>
						)}
					</MenuItem> */}
					<MenuItem
						onClick={() => handleReenviarToken(row)}
						style={{ color: APP_CONFIG.mainCollors.secondary }}
					>
						Reenviar Token de Aprovação
					</MenuItem>
					{row.row.status === 'pending' ? (
						<MenuItem
							onClick={() => handleFinalizar(row)}
							style={{ color: APP_CONFIG.mainCollors.secondary }}
						>
							Solicitar Finalização Cadastro. DOC.
						</MenuItem>
					) : (
						''
					)}
					<MenuItem
						onClick={() => handleGerenciarUsuario(row)}
						style={{ color: APP_CONFIG.mainCollors.secondary }}
					>
						Gerenciar Conta
					</MenuItem>
					{row.row.user && row.row.user.verificar_device_bloqueado ? (
						<MenuItem
							onClick={() => {
								setDesbloquerId({
									...desbloquerId,
									conta_id: row.row.id,
									verificacao_seguranca: {
										...row.row.verificacao_seguranca,
									},
								});
								setAnchorEl(null);
								setIsModalDesbloquearOpen(true);
							}}
							style={{ color: APP_CONFIG.mainCollors.secondary }}
						>
							Desbloquear Dispositivo
						</MenuItem>
					) : (
						<MenuItem
							onClick={() => {
								setBloquerDispositivoData({
									...bloquerDispositivoData,
									conta_id: row.row.id,
								});
								setAnchorEl(null);
								setIsModalBloquearOpen(true);
							}}
							style={{ color: APP_CONFIG.mainCollors.secondary }}
						>
							Bloquear Dispositivo
						</MenuItem>
					)}
					<MenuItem
						onClick={() => {
							setIsModalBlackListOpen(true);
							setAnchorEl(false);
							setNegarCadastroId(row.row.id);
						}}
						style={{ color: APP_CONFIG.mainCollors.secondary }}
					>
						Negar Cadastro
					</MenuItem>
				</Menu>
				<Dialog open={isModalDesbloquearOpen}>
					<Box width="500px" padding="20px">
						<DialogTitle>Desbloquear Dispositivo</DialogTitle>
						<Box style={{ display: 'flex', flexDirection: 'row' }}>
							<Typography style={{ marginRight: '10px' }}>
								Dados Verificados:
							</Typography>
							<Typography
								style={{
									color: desbloquerId.email_verificado
										? 'green'
										: 'red',
								}}
							>
								Email
							</Typography>
							{desbloquerId.email_verificado ? (
								<CheckIcon style={{ marginLeft: 5, color: 'green' }} />
							) : (
								<ClearIcon style={{ marginLeft: 5, color: 'red' }} />
							)}
							<Typography
								style={{
									color: desbloquerId.celular_verificado
										? 'green'
										: 'red',
								}}
							>
								Celular
							</Typography>
							{desbloquerId.celular_verificado ? (
								<CheckIcon style={{ marginLeft: 5, color: 'green' }} />
							) : (
								<ClearIcon style={{ marginLeft: 5, color: 'red' }} />
							)}
						</Box>
						<Box
							display="flex"
							justifyContent="space-around"
							marginTop="20px"
						>
							<CustomButton
								color="black"
								onClick={() => {
									setIsModalDesbloquearOpen(false);
									setDesbloquerId({
										conta_id: '',
										verificacao_seguranca: {},
									});
								}}
							>
								Cancelar
							</CustomButton>
							<CustomButton
								color="yellow"
								onClick={handleDesbloquearDispositivo}
							>
								Confirmar
							</CustomButton>
						</Box>
					</Box>
				</Dialog>
				<Dialog open={isModalBloquearOpen}>
					<Box width="500px" padding="20px">
						<DialogTitle>Bloquear Dispositivo</DialogTitle>
						<Box style={{ display: 'flex', flexDirection: 'column' }}>
							<TextField
								label="Descrição"
								value={bloquerDispositivoData.descricao}
								onChange={(e) =>
									setBloquerDispositivoData({
										...bloquerDispositivoData,
										descricao: e.target.value,
									})
								}
								error={bloquearErrors.descricao ? true : false}
							/>
							<FormHelperText
								style={{
									marginBottom: '6px',

									alignSelf: 'center',
									color: 'red',
								}}
							>
								{bloquearErrors.descricao
									? bloquearErrors.descricao.join(' ')
									: ''}
							</FormHelperText>
						</Box>
						<Box
							display="flex"
							justifyContent="space-around"
							marginTop="20px"
						>
							<CustomButton
								color="black"
								onClick={() => {
									setIsModalBloquearOpen(false);
									setBloquerDispositivoData({
										conta_id: '',
										descricao: '',
									});
								}}
							>
								Cancelar
							</CustomButton>
							<CustomButton
								color="yellow"
								onClick={handleBloquearDispositivo}
							>
								Confirmar
							</CustomButton>
						</Box>
					</Box>
				</Dialog>
				<Dialog open={isModalBlackListOpen}>
					<Box
						width="500px"
						padding="20px"
						justifyContent="center"
						alignItems="center"
					>
						<DialogTitle>Desbloquear Dispositivo</DialogTitle>
						<Typography style={{ textAlign: 'center' }}>
							Deseja adicionar a foto desse usuário na BlackList{' '}
							<b>
								{
									'(bloquear a foto do usuário em todos os outros/próximos cadastros)'
								}
							</b>
							?
						</Typography>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<Box style={{ marginTop: '5px', marginBottom: '5px' }}>
								<Button
									variant="contained"
									style={{
										backgroundColor: APP_CONFIG.mainCollors.primary,
										color: 'white',
									}}
									onClick={async () => {
										const { success, status } = await dispatch(
											postBlackListSelfieAction(
												token,
												negarCadastroId,
												false
											)
										);
										if (success) {
											toast.success('Cadastro negado com sucesso!');
											setNegarCadastroId('');
											setIsModalBlackListOpen(false);
										} else {
											toast.error(
												'Algo de errado aconteceu!' + status
											);
											setNegarCadastroId('');
											setIsModalBlackListOpen(false);
										}
									}}
								>
									Bloquear e não Adicionar à BlackList
								</Button>
							</Box>
							<Box style={{ marginTop: '5px', marginBottom: '5px' }}>
								<Button
									variant="contained"
									style={{
										backgroundColor: 'black',
										color: 'white',
									}}
									onClick={async () => {
										const { success, status } = await dispatch(
											postBlackListSelfieAction(
												token,
												negarCadastroId,
												true
											)
										);
										if (success) {
											toast.success(
												'Cadastro negado com sucesso! Foto adicionada à blacklist'
											);
											setNegarCadastroId('');
											setIsModalBlackListOpen(false);
										} else {
											toast.error(
												'Algo de errado aconteceu!' + status
											);
											setNegarCadastroId('');
											setIsModalBlackListOpen(false);
										}
									}}
								>
									Bloquear e Adicionar à BlackList
								</Button>
							</Box>

							<Box style={{ marginTop: '5px', marginBottom: '5px' }}>
								<Button
									variant="contained"
									style={{
										backgroundColor: '',
									}}
									onClick={() => {
										setNegarCadastroId('');
										setIsModalBlackListOpen(false);
									}}
								>
									Voltar
								</Button>
							</Box>
						</Box>
					</Box>
				</Dialog>
			</Box>
		);
	};

	useEffect(() => {
		if (!isEqual(filters, filtersComparation)) {
			localStorage.setItem(
				filters_gerenciar_contas,
				JSON.stringify({ ...filters })
			);
		}
	}, [filters]);

	useEffect(() => {
		const getLocalFilters = JSON.parse(
			localStorage.getItem(filters_gerenciar_contas)
		);
		if (getLocalFilters) {
			setFilters(getLocalFilters);
		}
	}, []);

	return (
		<Box className={classes.root}>
			<Box className={classes.headerContainer}>
				<Box style={{ marginBottom: '20px' }}>
					<Typography className={classes.pageTitle}>Contas</Typography>
				</Box>
				<Box
					style={{
						width: '100%',
						backgroundColor: APP_CONFIG.mainCollors.backgrounds,
						borderTopLeftRadius: 27,
						borderTopRightRadius: 27,
					}}
				>
					<Box style={{ margin: 30 }}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={5}>
								<TextField
									fullWidth
									placeholder="Pesquisar por nome, documento, email..."
									size="small"
									variant="outlined"
									style={{
										marginRight: '10px',
									}}
									value={filters.like}
									onChange={(e) => {
										setPage(1);
										setFilters({
											...filters,
											like: e.target.value,
										});
									}}
								/>
							</Grid>

							<Grid item xs={12} sm={3}>
								<TextField
									fullWidth
									placeholder="Pesquisar por ID Seller/Holder"
									size="small"
									variant="outlined"
									style={{
										marginRight: '10px',
									}}
									value={filters.seller}
									onChange={(e) => {
										setPage(1);
										setFilters({
											...filters,
											seller: e.target.value,
										});
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={1}>
								<Select
									style={{
										marginTop: '10px',
										color: APP_CONFIG.mainCollors.secondary,
									}}
									variant="outlined"
									fullWidth
									value={filters.tipo}
									onChange={(e) =>
										setFilters({ ...filters, tipo: e.target.value })
									}
								>
									<MenuItem
										value={' '}
										style={{
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										Tipo
									</MenuItem>
									<MenuItem
										value={'1'}
										style={{
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										Pessoa Física
									</MenuItem>
									<MenuItem
										value={'2'}
										style={{
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										Pessoa Jurídica
									</MenuItem>
								</Select>
							</Grid>

							<Grid item xs={12} sm={1}>
								<Select
									style={{
										marginTop: '10px',
										color: APP_CONFIG.mainCollors.secondary,
									}}
									variant="outlined"
									fullWidth
									value={filters.status}
									onChange={(e) =>
										setFilters({ ...filters, status: e.target.value })
									}
								>
									<MenuItem
										value={' '}
										style={{
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										Status
									</MenuItem>
									<MenuItem
										value={'enabled'}
										style={{
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										Ativo
									</MenuItem>
									<MenuItem
										value={'approved'}
										style={{
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										Aprovado
									</MenuItem>
									<MenuItem
										value={'pending'}
										style={{
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										Pendente
									</MenuItem>
									<MenuItem
										value={'denied'}
										style={{
											color: APP_CONFIG.mainCollors.secondary,
										}}
									>
										Negado
									</MenuItem>
								</Select>
							</Grid>

							<Grid item xs={12} sm={1}>
								<Box
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'center',
										height: '100%',
										width: '100%',
									}}
								>
									<CustomButton
										color="purple"
										onClick={handleExportar}
									>
										<Box display="flex" alignItems="center">
											<ViewListIcon />
											Exportar
										</Box>
									</CustomButton>
								</Box>
							</Grid>
							<Grid item xs={12} sm={1}>
								<Box
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'center',
										height: '100%',
										width: '100%',
									}}
								>
									<CustomButton
										color="red"
										onClick={() => {
											setFilters(filtersComparation);
											localStorage.setItem(
												filters_gerenciar_contas,
												JSON.stringify({ ...filtersComparation })
											);
										}}
									>
										<Box display="flex" alignItems="center">
											<DeleteIcon />
											Limpar
										</Box>
									</CustomButton>
								</Box>
							</Grid>
							<Grid item xs={12} sm={5}>
								<TextField
									fullWidth
									placeholder="Pesquisar por ID"
									size="small"
									variant="outlined"
									style={{
										marginRight: '10px',
									}}
									value={filters.id}
									onChange={(e) => {
										setPage(1);
										setFilters({
											...filters,
											id: e.target.value,
										});
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={3}>
								<TextField
									fullWidth
									placeholder="Pesquisar por numero de documento"
									size="small"
									variant="outlined"
									style={{
										marginRight: '10px',
									}}
									value={filters.numero_documento}
									onChange={(e) => {
										setPage(1);
										setFilters({
											...filters,
											numero_documento: e.target.value,
										});
									}}
								/>
							</Grid>
						</Grid>
					</Box>
					<Typography
						className={classes.pageTitle}
						style={{ marginLeft: '30px', marginBottom: '30px' }}
					>
						CONTAS RECENTES
					</Typography>
				</Box>
				<Box className={classes.tableContainer}>
					{listaContas.data && listaContas.per_page ? (
						<Box minWidth={!matches ? '800px' : null}>
							<CustomTable
								columns={columns ? columns : null}
								data={listaContas.data}
								Editar={Editar}
							/>
						</Box>
					) : (
						<Box width="60vw">
							<LinearProgress color="secondary" />
						</Box>
					)}
					<Box
						display="flex"
						alignSelf="flex-end"
						marginTop="8px"
						justifyContent="space-between"
					>
						<Pagination
							variant="outlined"
							color="secondary"
							size="large"
							count={listaContas.last_page}
							onChange={handleChangePage}
							page={page}
						/>
						<IconButton
							style={{
								backgroundColor: 'white',
								boxShadow: '0px 0px 5px 0.7px grey',
							}}
							onClick={() => window.location.reload(false)}
						>
							<RefreshIcon></RefreshIcon>
						</IconButton>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default ListaDeContas;
