import SettingsIcon from '@material-ui/icons/Settings';
import {
	Box,
	FormControlLabel,
	FormHelperText,
	makeStyles,
	Modal,
	Switch,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTextField from '../CustomTextField/CustomTextField';
import useAuth from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import {
	cleanGerarQrCodeAction,
	clearQrCodeCobrar,
	postGerarQrCodeAction,
} from '../../actions/actions';
import CustomButton from '../CustomButton/CustomButton';
import ReactCodeInput from 'react-code-input';
import tokenImage from '../../assets/vBankPJAssets/tokenImage.svg';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import QRCode from 'react-qr-code';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import CurrencyInput from 'react-currency-input';
import { APP_CONFIG } from '../../constants/config';

const useStyles = makeStyles((theme) => ({
	modal: {
		outline: ' none',
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'center',
		position: 'absolute',

		top: '10%',
		left: '25%',
		/* transform: 'translate(-50%, -50%)', */
		width: '50%',
		height: '80%',
		backgroundColor: 'white',
		/* bgcolor: 'background.paper', */
		border: '0px solid #000',
		boxShadow: 24,
		/* p: 5, */
	},
	currencyField: {
		fontFamily: 'Montserrat-Regular',
		/* fontWeight: 'bold', */
		color: 'white',
	},
}));

const CobrarContainer = ({ title, ...rest }) => {
	const classes = useStyles();
	const { section } = useParams();
	const theme = useTheme();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const token = useAuth();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const qrCodeCobrar = useSelector((state) => state.qrCodeCobrar);
	const [openModal, setOpenModal] = useState(false);
	const [errors, setErrors] = useState('');

	const [cobrar, setCobrar] = useState({
		mensagem: '',
		valor: 0,
	});

	const handleGerarQrCode = async () => {
		setLoading(true);
		toast.warning('Gerando QrCode, por favor aguarde.');
		const resGerarQrCode = await dispatch(
			postGerarQrCodeAction(token, cobrar.mensagem, cobrar.valor)
		);
		if (resGerarQrCode) {
			setErrors(resGerarQrCode);
			toast.error('Falha ao gerar QrCode');
			setLoading(false);
		} else {
			toast.success('QrCode gerado com sucesso');
			setLoading(false);
		}
	};

	const downloadQrCode = (contentType, base64Data, fileName) => {
		const linkSource = `data:${contentType};base64,${base64Data}`;
		const downloadLink = document.createElement('a');
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	};

	useEffect(() => {
		dispatch(clearQrCodeCobrar());
	}, []);

	return (
		<>
			<Typography
				style={{
					fontFamily: 'Montserrat-ExtraBold',
					fontSize: '16px',
					color: APP_CONFIG.mainCollors.primary,
					marginTop: '30px',
					marginLeft: '40px',
				}}
			>
				Cobrar
			</Typography>
			<LoadingScreen isLoading={loading} />

			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginTop: '10px',
				}}
			>
				<Box
					style={{
						width: '90%',
						height: '1px',
						backgroundColor: APP_CONFIG.mainCollors.primary,
					}}
				/>

				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '10px',
						alignItems: 'center',
						flexDirection: 'column',
					}}
				>
					<Box
						style={{
							backgroundColor: APP_CONFIG.mainCollors.primary,
							display: 'flex',
							flexDirection: 'column',
							/* height: '200px', */
							padding: '20px',
							borderRadius: '17px',
							alignItems: 'center',
							/* justifyContent: 'center', */
						}}
					>
						<Typography
							style={{
								fontFamily: 'Montserrat-ExtraBold',
								fontSize: '13px',
								color: 'white',
								marginTop: '10px',
							}}
						>
							Valor a cobrar
						</Typography>

						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								alignSelf: 'center',
							}}
						>
							<Box style={{ marginTop: '20px' }}>
								<CurrencyInput
									style={{
										marginBottom: '6px',
										width: '80%',
										alignSelf: 'center',
										textAlign: 'center',
										height: 40,
										fontSize: 20,
										border: 'none',
										color: '#fff',
										backgroundColor: 'transparent',
										fontFamily: 'Montserrat-Regular',
									}}
									prefix="R$"
									decimalSeparator=","
									thousandSeparator="."
									value={cobrar.valor}
									onChange={(e, value) => {
										setCobrar({
											...cobrar,
											valor: value,
										});
									}}
								/>
								{errors.valor ? (
									<FormHelperText
										style={{
											fontSize: 14,
											textAlign: 'center',
											fontFamily: 'Montserrat-ExtraBold',
											color: 'red',
										}}
									>
										{errors.valor.join(' ')}
									</FormHelperText>
								) : null}
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '90%',
						marginTop: '30px',
					}}
				>
					<Box style={{ marginTop: '20px' }}>
						<TextField
							variant="outlined"
							fullWidth
							label="Descrição"
							value={cobrar.mensagem}
							error={errors.mensagem}
							helperText={
								errors.mensagem ? errors.mensagem.join(' ') : null
							}
							onChange={(e) => {
								setCobrar({
									...cobrar,
									mensagem: e.target.value,
								});
							}}
						/>
					</Box>
				</Box>

				<Box
					style={{
						marginTop: '30px',
						marginBottom: '15px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{qrCodeCobrar && qrCodeCobrar.codigo ? (
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
								padding: '20px',
							}}
						>
							<QRCode value={qrCodeCobrar.codigo} id="qr-gen" />
							{qrCodeCobrar.transacao.transaction.pix_qrcode
								.GetPixQRCodeByIdInfo.QRCodeBase64 ? (
								<Box style={{ marginTop: '10px' }}>
									<CustomButton
										color="black"
										onClick={() =>
											downloadQrCode(
												'image/png',
												qrCodeCobrar.transacao.transaction
													.pix_qrcode.GetPixQRCodeByIdInfo
													.QRCodeBase64,
												'QrCode'
											)
										}
									>
										<Typography
											style={{
												fontFamily: 'Montserrat-Regular',
												fontSize: '14px',
												color: 'white',
											}}
										>
											Baixar QrCode
										</Typography>
									</CustomButton>
								</Box>
							) : null}
						</Box>
					) : null}

					<CustomButton color="purple" onClick={handleGerarQrCode}>
						<Typography
							style={{
								fontFamily: 'Montserrat-Regular',
								fontSize: '14px',
								color: 'white',
							}}
						>
							Gerar QrCode
						</Typography>
					</CustomButton>
				</Box>
			</Box>
		</>
	);
};

export default CobrarContainer;
